// Actions Types
import {EVENTS_LOADING, EVENTS_SUCCESS, EVENTS_ERRORS} from "../../Types/Dashboard/EventsType/EventsType";

const EventsReducers = (state, {payload, type}) => {
    switch (type) {
        case EVENTS_LOADING :
            return {
                ...state,
                Events: {
                    ...state.Events,
                    Loading: true,
                    Error: false
                }
            }
        case EVENTS_SUCCESS :
            return {
                ...state,
                Events: {
                    ...state.Events,
                    Loading: false,
                    Data: payload,
                }
            }
        case EVENTS_ERRORS :
            return {
                ...state,
                Events: {
                    ...state.Events,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default EventsReducers;
import React from 'react';

// Store Provider
import MasterStore from "./Sources/Stores/MasterStore";

// Routs Provider
import RoutesProvider from "./Routes/RoutesProvider";

// Notifications
import { ToastContainer } from 'react-toastify';

// Material-UI Style
import {CssBaseline} from "@material-ui/core";

function Application() {
    return (
        <React.Fragment>
            <MasterStore>
                <RoutesProvider>
                    <CssBaseline />
                    <ToastContainer />
                </RoutesProvider>
            </MasterStore>
        </React.Fragment>
    );
}

export default Application;

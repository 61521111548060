// API Instance
import AxiosInstance from "../../../../Database/Views/AxiosInstance";

// Actions Types
import {EVENT_VIEW_ERRORS, EVENT_VIEW_LOADING, EVENT_VIEW_SUCCESS} from "../../../Types/Views/EventViewsType/EventViewsType";

const EventViewsAction = (EventID) => (EventViewsDispatch) => {

    EventViewsDispatch({
        type: EVENT_VIEW_LOADING,
    });

    AxiosInstance()
        .get(`/events/${EventID}/style`)
        .then(Response => {
            EventViewsDispatch({
                type: EVENT_VIEW_SUCCESS,
                payload: Response.data,
            })
        })
        .catch(Error => {
            EventViewsDispatch({
                type: EVENT_VIEW_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
        });

}

export default EventViewsAction;
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    input: {
        marginBottom: theme.spacing(1),
        '& label': {
            transform: 'translate(30px, 17px) scale(1)',
            '&.MuiInputLabel-shrink': {
                transform: 'translate(30px, -6px) scale(0.75)',
            },
        },
        '& input': {
            padding: '15px 30px',
        },
        '& textarea': {
            padding: '15px 30px',
        },
        '& fieldset': {
            padding: '0 23px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '15px 30px',
        },
        '& .MuiOutlinedInput-multiline': {
            padding: 0,
        },
        '& .MuiFormHelperText-contained': {
            color: theme.palette.error.main,
        },
    },
    modal: {
        top: `50%`,
        left: `50%`,position: 'absolute',
        transform: `translate(-50%, -50%)`,
    },
    colorButton: {
        zIndex: '9',
        right: '22px',
        bottom: '17px',
        height: '30px',
        position: 'absolute',
        boxShadow: '0px 0px 3px 1px #888888',
    },
    titles: {
        margin: 0,
        textTransform: 'uppercase',
    },
    lines: {
        width: '100%',
        margin: '24px',
    },
    box: {
        textAlign: 'center',
        padding: '35px',
        marginBottom: '15px',
        border: '1px dashed #525252',
        backgroundColor: '#c4c4c4',
    }
}));

export default useStyles;
import React, {useEffect, useState} from 'react';

// Actions
import EventsListAction from "../../../../Sources/Actions/Dashboard/EventsListAction/EventsListAction";
import EventsDataAction from "../../../../Sources/Actions/Dashboard/EventsDataAction/EventsDataAction";
import AllEventsDataAction from "../../../../Sources/Actions/Dashboard/AllEventsDataAction/AllEventsDataAction";
import RegisteredAction from "../../../../Sources/Actions/Dashboard/ExportToExcelAction/RegisteredAction";
import SurveyAction from "../../../../Sources/Actions/Dashboard/ExportToExcelAction/SurveyAction";
import CertificateAction from "../../../../Sources/Actions/Dashboard/ExportToExcelAction/CertificateAction";

// Material-UI Components
import { Grid, Typography, TextField, MenuItem, Card, CardMedia, CardContent, CardActions, Button } from '@material-ui/core';

// Material-UI Icons
import { Description } from '@material-ui/icons';

// Component Style
import useStyles from './AnalysisLayout_Styles';

const AnalysisLayout = () => {
    const classes = useStyles();
    const [EventID, setEventID] = useState('');
    const [EventName, setEventName] = useState('');
    const [EventsList, setEventsList] = useState([]);
    const [EventsData, setEventsData] = useState({});
    const [AllEventsData, setAllEventsData] = useState({});

    const EventChange = (event) => {
        setEventID(event.target.value);
        setEventName(event.nativeEvent.target.innerText);
    };

    useEffect(() => {
        EventsListAction(setEventsList)
    }, [setEventsList]);

    useEffect(() => {
        if ((!EventID) || (EventID === '0')) {
            AllEventsDataAction(setAllEventsData);
            setEventsData({});
            setEventName('');
        }
    }, [setEventsData, EventID]);

    useEffect(() => {
        if ((!!EventID) && (EventID !== '0')) {
            EventsDataAction(EventID)(setEventsData);
            setAllEventsData({});
        }
    }, [setEventsData, EventID]);

    const ExportRegistered = () => {
        RegisteredAction(EventID);
    };

    const ExportSurvey = () => {
        SurveyAction(EventID);
    };

    const ExportCertificate = () => {
        CertificateAction(EventID);
    };

    return (
        <React.Fragment>
            <Grid container className={classes.root}>
                <Grid container justify={'center'}>
                    <Grid item md={4} xs={6}>
                        <Typography gutterBottom variant="h5" component="h2" align={'center'}>
                            {!!EventName ? (EventName + ' Event Stats') : 'All Events Stats'}
                        </Typography>
                        <TextField fullWidth select value={EventID || 0} onChange={EventChange} variant="outlined" label="Event" className={classes.input}>
                            <MenuItem value={'0'}>All Events</MenuItem>
                            {EventsList.length ? EventsList.map((Event) => (
                                    <MenuItem key={Event.id} value={Event.id}>
                                        {Event.name}
                                    </MenuItem>))
                                :
                                <MenuItem disabled>No Events Available</MenuItem>
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container justify={'center'} spacing={3}>
                    <Grid item md={3} xs={12} className={classes.singleCard}>
                        <Card className={classes.cardContent}>
                            <CardMedia
                                className={classes.cardMedia}
                                image="/Assets/Images/Register.svg"
                                title="Registered"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="h2">
                                    Registered
                                </Typography>
                                <Typography gutterBottom variant="h4" color="textSecondary">
                                    {EventsData.users_count || AllEventsData.users_count || 0}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={ExportRegistered} size="small" disabled={(EventsData.users_count === 0) || (!Object.keys(EventsData).length)} startIcon={<Description />}>
                                    Export To Excel
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12} className={classes.singleCard}>
                        <Card className={classes.cardContent}>
                            <CardMedia className={classes.cardMedia} image="/Assets/Images/Survey.svg" title="Survey" />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="h2">
                                    Survey
                                </Typography>
                                <Typography gutterBottom variant="h4" color="textSecondary">
                                    {EventsData.surveys_count || AllEventsData.surveys_count || 0}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={ExportSurvey} size="small" disabled={(EventsData.surveys_count === 0) || (!Object.keys(EventsData).length)} startIcon={<Description />}>
                                    Export To Excel
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12} className={classes.singleCard}>
                        <Card className={classes.cardContent}>
                            <CardMedia className={classes.cardMedia} image="/Assets/Images/Certificate.svg" title="Certificate" />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="h2">
                                    Certificate
                                </Typography>
                                <Typography gutterBottom variant="h4" color="textSecondary">
                                    {EventsData.certificates_count || AllEventsData.certificates_count || 0}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={ExportCertificate} size="small" disabled={(EventsData.certificates_count === 0) || (!Object.keys(EventsData).length)} startIcon={<Description />}>
                                    Export To Excel
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default AnalysisLayout;
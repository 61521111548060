import React, {useContext, useEffect, useState} from 'react';

// Stores
import { DashboardStore } from "../../../Sources/Stores/Dashboard/DashboardStore/DashboardStore";

// Actions
import EventUsersAction from "../../../Sources/Actions/Dashboard/EventUsersAction/EventUsersAction";
import DeleteEventUsersAction from "../../../Sources/Actions/Dashboard/DeleteEventUsersAction/DeleteEventUsersAction";
import AddEventUsersAction from "../../../Sources/Actions/Dashboard/AddEventUsersAction/AddEventUsersAction";

// Material-UI Components
import { Modal, Backdrop, Fade, Box, Typography, Grid, IconButton, Button, CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

// Material-UI Icons
import { Delete } from '@material-ui/icons';

// Chip Input
import ChipInput from 'material-ui-chip-input';

// Component Styles
import useStyles from './EventUsersModal_Styles';

const EventUsersModal = ({EventHash}) => {
    const classes = useStyles();
    const [ GridData, setGridData ] = useState([]);
    const [ AttendantsEmails, setAttendantsEmails ] = useState([]);
    const { EventUsersModal, setEventUsersModal, EventUsersState: {EventUsers: {Data, Loading}},
            EventUsersDispatch, EventsDispatch, AddEventUsersState: {AddEventUsers: {Loading: AddLoading}},
            AddEventUsersDispatch, DeleteEventUsersState: {DeleteEventUsers: {Loading: DeleteLoading}},
            DeleteEventUsersDispatch } = useContext(DashboardStore);

    const columns = [
        { field: 'email', headerName: 'User Email', minWidth: 400 },
    ];

    const handleModalClose = () => {
        setAttendantsEmails([]);
        setEventUsersModal(false);
        setGridData([]);
    };

    const AttendantEmail = (chip) => {
        const IsEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
        (IsEmail.test(chip)) && setAttendantsEmails([...AttendantsEmails, chip]);
    }

    useEffect(() => {
        if (EventUsersModal) {
            EventUsersAction(EventHash)(EventUsersDispatch);
        }
    }, [EventHash, EventUsersDispatch, EventUsersModal]);

    const DeleteAttendants = () => {
        DeleteEventUsersAction(EventHash)(GridData)(setEventUsersModal)(EventsDispatch)(setGridData)(setAttendantsEmails)(DeleteEventUsersDispatch);
    };

    const AddAttendants = () => {
        AddEventUsersAction(EventHash)(AddEventUsersDispatch)(AttendantsEmails)(setGridData)(setEventUsersModal)(EventsDispatch)(setAttendantsEmails);
    };

    return (
        <React.Fragment>
            <Modal className={classes.modal} open={EventUsersModal} onClose={handleModalClose} BackdropComponent={Backdrop} BackdropProps={{timeout: 500}}>
                <Fade in={EventUsersModal}>
                    <Box className={classes.container}>
                        <Typography className={classes.title} variant="h6" gutterBottom>
                            Event Users
                            {!!GridData.length &&
                                <IconButton aria-label="delete" disabled={DeleteLoading} color="secondary" onClick={DeleteAttendants}>
                                    {DeleteLoading ? <CircularProgress /> : <Delete/>}
                                </IconButton>
                            }
                        </Typography>

                        {!Loading &&
                            <Grid className={classes.table}>
                                <DataGrid rows={Data} columns={columns} rowHeight={38} hideFooter checkboxSelection onSelectionModelChange={(GridData) => setGridData(GridData)} />
                            </Grid>
                        }

                        <Grid container>
                            <Grid item md={9} xs={12}>
                                <ChipInput fullWidth variant={"outlined"} value={AttendantsEmails} label={'Additional Attendants'} placeholder={'Enter Email and Click Enter'}
                                   onDelete={(chip) => setAttendantsEmails(AttendantsEmails.filter((Value) => Value !== chip))} onAdd={AttendantEmail} />
                            </Grid>
                            <Grid item md={3} xs={12} className={classes.button}>
                                <Button variant="contained" disabled={AddLoading || !AttendantsEmails.length} color="primary" onClick={AddAttendants}>
                                    {(AddLoading)? <CircularProgress /> : <React.Fragment>Add Attendants</React.Fragment>}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </React.Fragment>
    );
};

export default EventUsersModal;
import React from 'react';

// React Router
import {Route, Switch} from "react-router-dom";

// Layouts
import LoginLayout from "../../../Layouts/Dashboard/AuthLayouts/LoginLayout/LoginLayout";
import ForgotPasswordLayout from "../../../Layouts/Dashboard/AuthLayouts/ForgotPasswordLayout/ForgotPasswordLayout";
import ResetPasswordLayout from "../../../Layouts/Dashboard/AuthLayouts/ResetPasswordLayout/ResetPasswordLayout";

// Component Style
import useStyles from './AuthDashboardContainer_Styles';

// Material-UI Components
import { Box } from "@material-ui/core";

const AuthDashboardContainer = () => {
    const classes = useStyles();
    
    return (
        <React.Fragment>
            <Box className={classes.root}>
                <Switch>
                    <Route exact path={"/auth/login"} component={() => <LoginLayout />}/>
                    <Route exact path={"/auth/forgot-password"} component={() => <ForgotPasswordLayout />}/>
                    <Route exact path={"/auth/reset-password"} component={() => <ResetPasswordLayout />}/>
                </Switch>
            </Box>
        </React.Fragment>
    );
};

export default AuthDashboardContainer;
// API Instance
import AxiosInstance from "../../../../Database/Views/AxiosInstance";

// Actions Types
import {EVENT_ERRORS, EVENT_LOADING, EVENT_SUCCESS} from "../../../Types/Views/EventType/EventType";
import {toast} from "react-toastify";

const EventAction = (History) => (EventID) => (EventDispatch) => {

    EventDispatch({
        type: EVENT_LOADING,
    });

    AxiosInstance()
        .get(`/events/${EventID}`)
        .then(Response => {
            EventDispatch({
                type: EVENT_SUCCESS,
                payload: Response.data,
            })
        })
        .catch(Error => {
            EventDispatch({
                type: EVENT_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
            if (Error.response.status === 404) {
                toast(`${Error.response.data.message}`, {
                    autoClose: 3000,
                    draggable: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    position: "top-right",
                    hideProgressBar: false,
                });
            }
            localStorage.removeItem(EventID);
        });

}

export default EventAction;
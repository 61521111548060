// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

const SurveyAction = (EventID) => {
    AxiosInstance()
        .get(`/dashboard/events/${EventID}/stats/survey/export`)
        .then(Response => {
            const Link = Response.data.data.url;
            const DownloadFile = document.body.appendChild(document.createElement("a"));
            DownloadFile.href = Link;
            DownloadFile.click();
            DownloadFile.remove();
        })
        .catch(Error);
}

export default SurveyAction;
import {useContext, useState} from 'react';

// React Router
import { useHistory } from 'react-router-dom';

// Store Provider
import { DashboardStore } from "../../../Sources/Stores/Dashboard/DashboardStore/DashboardStore";

// Actions
import LoginDashboardAction from "../../../Sources/Actions/Dashboard/LoginDashboardAction/LoginDashboardAction";

// Forms Validations
import FormValidation from '../../Validation/FormsValidations';

const LoginFunctions = () => {
    const History = useHistory();
    const [FormErrors, setFormErrors] = useState({});
    const [LoginDashboard, setLoginDashboard] = useState({});
    const {LoginDashboardState: {LoginDashboard: {Loading, Error, Data}}, LoginDashboardDispatch} = useContext(DashboardStore);

    // Fields Validation
    const FieldsValidation = {
        email : {
            error: "",
            validate: 'email',
        },
        password: {
            error: "",
            minLength: 6,
        }
    };

    // Get Form Data
    const FormData = (event) => {
        const { target: { value, name } } = event;
        setLoginDashboard({...LoginDashboard, [name]: value});

        // Set Errors
        const error = FormValidation(name, value, FieldsValidation) || '';
        setFormErrors({...FormErrors, [name]: error});
    };

    // Form Validation
    const FormValid =
        !LoginDashboard.email?.length ||
        !LoginDashboard.password?.length

    // Form Submit
    const FormSubmit = () => {
        LoginDashboardAction(History)(LoginDashboard)(LoginDashboardDispatch);
    }

    return {LoginDashboard, FormData, FormValid, FormSubmit, Loading, Error, Data, FormErrors};
}

export default LoginFunctions;
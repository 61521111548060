import axios from 'axios';

const AxiosInstance = (History = null) => {
    const BaseURL = process.env.REACT_APP_DATABASE_URL;
    const URLPathName = window.location.pathname.split('/')[2];
    const EventID = URLPathName.substring(URLPathName.lastIndexOf('/') + 1);
    let   Headers = {};

    if (localStorage[EventID]) {
        Headers.Authorization = `Bearer ${localStorage[EventID]}`;
    }

    const AxiosCreation = axios.create({
        baseURL: BaseURL,
        headers: Headers,
    })

    AxiosCreation.interceptors.response.use(
        (Response) =>
            new Promise((Resolve) => {
                Resolve(Response);
            }),
        (Error) => {
            if (Error.response.status === 401) {
                localStorage.removeItem(EventID);
                if (History) {
                    History.push('/');
                } else {
                    window.location = '/';
                }
            } else {
                return new Promise((Resolve, Reject) => {
                    Reject(Error);
                })
            }
        }
    )
    return AxiosCreation;
}

export default AxiosInstance;
// Actions Types
import {NEW_EVENT_LOADING, NEW_EVENT_SUCCESS, NEW_EVENT_ERRORS} from "../../Types/Dashboard/NewEventType/NewEventType";

const NewEventReducers = (state, {payload, type}) => {
    switch (type) {
        case NEW_EVENT_LOADING :
            return {
                ...state,
                NewEvent: {
                    ...state.NewEvent,
                    Loading: true,
                    Error: false
                }
            }
        case NEW_EVENT_SUCCESS :
            return {
                ...state,
                NewEvent: {
                    ...state.NewEvent,
                    Loading: false,
                    Data: payload,
                }
            }
        case NEW_EVENT_ERRORS :
            return {
                ...state,
                NewEvent: {
                    ...state.NewEvent,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default NewEventReducers;
// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Actions Types
import {EVENTS_LOADING, EVENTS_SUCCESS, EVENTS_ERRORS} from "../../../Types/Dashboard/EventsType/EventsType";

const EventsAction = (EventsDispatch) => {

    EventsDispatch({
        type: EVENTS_LOADING
    });

    AxiosInstance()
        .get(`/dashboard/events`)
        .then(Response => {
            EventsDispatch({
                type: EVENTS_SUCCESS,
                payload: Response.data
            });
        })
        .catch(Error => (
            EventsDispatch({
                type: EVENTS_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API'
            })
        ));
}

export default EventsAction;
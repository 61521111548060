// Actions Types
import {DELETE_EVENT_USERS_LOADING, DELETE_EVENT_USERS_SUCCESS, DELETE_EVENT_USERS_ERRORS} from "../../Types/Dashboard/DeleteEventUsersType/DeleteEventUsersType";

const DeleteEventUsersReducers = (state, {payload, type}) => {
    switch (type) {
        case DELETE_EVENT_USERS_LOADING :
            return {
                ...state,
                DeleteEventUsers: {
                    ...state.DeleteEventUsers,
                    Loading: true,
                    Error: false
                }
            }
        case DELETE_EVENT_USERS_SUCCESS :
            return {
                ...state,
                DeleteEventUsers: {
                    ...state.DeleteEventUsers,
                    Loading: false,
                    Data: payload,
                }
            }
        case DELETE_EVENT_USERS_ERRORS :
            return {
                ...state,
                DeleteEventUsers: {
                    ...state.DeleteEventUsers,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default DeleteEventUsersReducers;
// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Notifications
import {toast} from 'react-toastify';

// Actions Types
import {EDIT_CLIENT_ERRORS, EDIT_CLIENT_LOADING, EDIT_CLIENT_SUCCESS} from "../../../Types/Dashboard/EditClientType/EditClientType";

const EditClientAction = (History) => (ClientID) => (FormFormat) => (EditClientDispatch) => {

    FormFormat.append('_method', 'put');

    EditClientDispatch({
        type: EDIT_CLIENT_LOADING,
    })

    AxiosInstance()
        .post(`/dashboard/clients/${ClientID}`, FormFormat)
        .then(Response => {
            EditClientDispatch({
                type: EDIT_CLIENT_SUCCESS,
                payload: Response.data,
            });
            History.push('/dashboard/clients');
            toast('Client Data Updated Is Success!', {
                autoClose: 3000,
                draggable: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                position: "top-right",
                hideProgressBar: false,
            });
        })
        .catch(Error => (
            EditClientDispatch({
                type: EDIT_CLIENT_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
        ));

}

export default EditClientAction;
// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Notifications
import {toast} from 'react-toastify';

// Actions Types
import {RESET_PASSWORD_ERRORS, RESET_PASSWORD_LOADING, RESET_PASSWORD_SUCCESS} from "../../../Types/Dashboard/ResetPasswordDashboardType/ResetPasswordDashboardType";

const ResetPasswordDashboardAction = (History) => (Token, Email) => ({password, password_confirmation}) => (ResetPasswordDashboardDispatch) => {

    ResetPasswordDashboardDispatch({
        type: RESET_PASSWORD_LOADING,
    })

    AxiosInstance()
        .post(`/dashboard/reset-password`, {password, password_confirmation}, { params: { token: Token, email: Email } })
        .then(Response => {
            ResetPasswordDashboardDispatch({
                type: RESET_PASSWORD_SUCCESS,
                payload: Response.data,
            });
            History.push('./login');
            toast(`${Response.data.message}`, {
                autoClose: 3000,
                draggable: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                position: "top-right",
                hideProgressBar: false,
            });
        })
        .catch(Error => {
            ResetPasswordDashboardDispatch({
                type: RESET_PASSWORD_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
        });

}

export default ResetPasswordDashboardAction;
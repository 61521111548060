// Actions Types
import {SHOW_EVENT_LOADING, SHOW_EVENT_SUCCESS, SHOW_EVENT_ERRORS} from "../../Types/Dashboard/ShowEventType/ShowEventType";

const ShowEventReducers = (state, {payload, type}) => {
    switch (type) {
        case SHOW_EVENT_LOADING :
            return {
                ...state,
                ShowEvent: {
                    ...state.ShowEvent,
                    Loading: true,
                    Error: false
                }
            }
        case SHOW_EVENT_SUCCESS :
            return {
                ...state,
                ShowEvent: {
                    ...state.ShowEvent,
                    Loading: false,
                    Data: payload,
                }
            }
        case SHOW_EVENT_ERRORS :
            return {
                ...state,
                ShowEvent: {
                    ...state.ShowEvent,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default ShowEventReducers;
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    input: {
        '& label': {
            transform: 'translate(30px, 17px) scale(1)',
            '&.MuiInputLabel-shrink': {
                transform: 'translate(30px, -6px) scale(0.75)',
            },
        },
        '& input': {
            padding: '15px 30px',
        },
        '& textarea': {
            padding: '15px 30px',
        },
        '& fieldset': {
            padding: '0 23px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '15px 30px',
        },
        '& .MuiOutlinedInput-multiline': {
            padding: 0,
        },
        '& .MuiFormHelperText-contained': {
            color: theme.palette.error.main,
        },
    },
    answer: {
        position: 'relative',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    answerButton: {
        borderRadius: 0,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.secondary.light,
        '&:hover': {
            color: theme.palette.secondary.light,
        }
    }
}));

export default useStyles;
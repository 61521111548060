import {useContext, useState, useEffect} from 'react';

// Store Provider
import { ViewsStore } from "../../../Sources/Stores/Views/ViewsStore/ViewsStore";

// Actions
import ProfileViewsAction from "../../../Sources/Actions/Views/ProfileViewsAction/ProfileViewsAction";
import CitiesAction from "../../../Sources/Actions/Views/ProfileViewsAction/CitiesAction";

// Forms Validations
import FormValidation from '../../Validation/FormsValidations';

const ProfileFunctions = () => {
    const [Cities, setCities] = useState({});
    const [FormErrors, setFormErrors] = useState({});
    const [ProfileViews, setProfileViews] = useState({});
    const {UserViewsState: {UserViews: {Loading, Error, Data}}, UserViewsDispatch} = useContext(ViewsStore);

    // Fields Validation
    const FieldsValidation = {
        first_name : {
            error: "",
            minLength: 3,
            validate: 'text',
        },
        last_name : {
            error: "",
            minLength: 3,
            validate: 'text',
        },
        mobile : {
            error: "",
            minLength: 9,
            maxLength: 14,
            validate: 'number',
        },
        national_id: {
            error: "",
            minLength: 3,
        },
        speciality: {
            error: "",
            minLength: 3,
            validate: 'text',
        },
        country_id: {},
        city_id: {},
    };

    // Get Form Data
    const FormData = (event) => {
        const { target: { value, name } } = event;
        setProfileViews({...ProfileViews, [name]: value});

        // Set Errors
        const error = FormValidation(name, value, FieldsValidation) || '';
        setFormErrors({...FormErrors, [name]: error});
    };

    // Get City
    useEffect(() => {
        if (ProfileViews.country_id) {
            CitiesAction(ProfileViews.country_id)(setCities);
        }
    }, [ProfileViews.country_id, setCities]);

    // Form Validation
    const FormValid =
        !ProfileViews.first_name?.length ||
        !ProfileViews.last_name?.length ||
        !ProfileViews.mobile?.length ||
        !ProfileViews.national_id?.length ||
        !ProfileViews.speciality?.length ||
        !(!!ProfileViews.country_id) ||
        !(!!ProfileViews.city_id)

    // Form Submit
    const FormSubmit = () => {
        ProfileViewsAction(ProfileViews)(UserViewsDispatch);
    }

    return {ProfileViews, FormData, FormValid, FormSubmit, Loading, Error, Data, Cities, FormErrors};
}

export default ProfileFunctions;
import React from 'react';

const EventInfoLayout = () => {
    return (
        <React.Fragment>
            <h1>Event Info</h1>
        </React.Fragment>
    );
};

export default EventInfoLayout;
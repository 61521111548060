import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        order:'1',
        width: '80%',
        display:'flex',
        padding: '15px',
        margin: '0 auto',
        maxWidth: '1600px',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection:'column',
        justifyContent:'flex-start',
        minHeight: 'calc(100vh - 104px)',
        backgroundColor: theme.palette.primary.contrastText,
        [theme.breakpoints.down('sm')]: {
            order:'2',
            width:'95%',
            padding: '25px',
        },
    },
    title: {
        padding: theme.spacing(2),
        '& h3': {
            margin: '0',
            fontSize: '40px',
            marginBottom: '25px',
            letterSpacing: '11px',
            textTransform:'uppercase',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(4),
            },
        },
        '& h6': {
            fontSize: '20px',
            marginBottom: '0',
            textTransform:'uppercase',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(2),
            },
        },
    },
    submit: {
        fontWeight: '400',
        padding: '8px 65px',
        width: 'max-content',
        borderRadius: '10px',
        margin: '16px auto 0',
        fontSize: theme.spacing(2),
        color: theme.palette.primary.contrastText,
        '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
        },
    },
    outlined: {
        fontWeight: '400',
        padding: '8px 27px',
        width: 'max-content',
        borderRadius: '10px',
        margin: '16px 15px 0 auto',
        fontSize: theme.spacing(2),
        '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
        },
        [theme.breakpoints.down('xs')]: {
            margin: 0,
        },
    },
    preview: {
        width: '100%',
        maxWidth: '650px',
        display:'flex',
        padding: '25px',
        marginTop: '25px',
        alignItems: 'center',
        justifyContent:'center',
    },
    certificate: {
        height: '0',
        width: '100%',
        position: 'relative',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        paddingTop: '63%',
        marginBottom: '25px',
        border: '1px solid #000',
    },
    userName: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: '19%',
        fontSize: '14px',
    }
}));

export default useStyles;
// Actions Types
import {USER_LOADING, USER_SUCCESS, USER_ERRORS} from "../../Types/Views/UserViewsType/UserViewsType";

const UserViewsReducers = (state, {payload, type}) => {
    switch (type) {
        case USER_LOADING :
            return {
                ...state,
                UserViews: {
                    ...state.UserViews,
                    Loading: true,
                    Error: false
                }
            }
        case USER_SUCCESS :
            return {
                ...state,
                UserViews: {
                    ...state.UserViews,
                    Loading: false,
                    Data: payload,
                }
            }
        case USER_ERRORS :
            return {
                ...state,
                UserViews: {
                    ...state.UserViews,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default UserViewsReducers;
// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Actions Types
import {CLIENT_INFO_LOADING, CLIENT_INFO_SUCCESS, CLIENT_INFO_ERRORS} from "../../../Types/Dashboard/ClientInfoType/ClientInfoType";

const ClientsAction = (ClientID) => (ClientInfoDispatch) => {

    ClientInfoDispatch({
        type: CLIENT_INFO_LOADING
    });

    AxiosInstance()
        .get(`/dashboard/clients/${ClientID}`)
        .then(Response => {
            ClientInfoDispatch({
                type: CLIENT_INFO_SUCCESS,
                payload: Response.data
            });
        })
        .catch(Error => (
            ClientInfoDispatch({
                type: CLIENT_INFO_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API'
            })
        ));
}

export default ClientsAction;
import { makeStyles } from '@material-ui/core/styles';

// Side Menu Width
const drawerWidth = '16%';

const useStyles = makeStyles((theme) => ({
    appBar: {
        boxShadow: 'unset',
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.primary.dark,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: '85%',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
}));

export default useStyles;
// Actions Types
import {RESET_PASSWORD_LOADING, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERRORS} from "../../Types/Dashboard/ResetPasswordDashboardType/ResetPasswordDashboardType";

const ForgotPasswordDashboardReducers = (state, {payload, type}) => {
    switch (type) {
        case RESET_PASSWORD_LOADING :
            return {
                ...state,
                ResetPasswordDashboard: {
                    ...state.ResetPasswordDashboard,
                    Loading: true,
                    Error: false
                }
            }
        case RESET_PASSWORD_SUCCESS :
            return {
                ...state,
                ResetPasswordDashboard: {
                    ...state.ResetPasswordDashboard,
                    Loading: false,
                    Data: payload,
                }
            }
        case RESET_PASSWORD_ERRORS :
            return {
                ...state,
                ResetPasswordDashboard: {
                    ...state.ResetPasswordDashboard,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default ForgotPasswordDashboardReducers;
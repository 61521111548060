import {useContext, useState} from 'react';

// React Router
import { useParams } from 'react-router-dom';

// Store Provider
import { ViewsStore } from "../../../Sources/Stores/Views/ViewsStore/ViewsStore";

// Actions
import LoginViewsAction from "../../../Sources/Actions/Views/LoginViewsAction/LoginViewsAction";

// Forms Validations
import FormValidation from '../../Validation/FormsValidations';

const LoginFunctions = () => {
    const {EventID} = useParams();
    const [FormErrors, setFormErrors] = useState({});
    const [LoginViews, setLoginViews] = useState({});
    const {UserViewsState: {UserViews: {Loading, Error, Data}}, UserViewsDispatch} = useContext(ViewsStore);

    // Fields Validation
    const FieldsValidation = {
        email : {
            error: "",
            validate: 'email',
        },
    };

    // Get Form Data
    const FormData = (event) => {
        const { target: { value, name } } = event;
        setLoginViews({...LoginViews, [name]: value});

        // Set Errors
        const error = FormValidation(name, value, FieldsValidation) || '';
        setFormErrors({...FormErrors, [name]: error});
    };

    // Form Validation
    const FormValid =
        !LoginViews.email?.length

    // Form Submit
    const FormSubmit = () => {
        LoginViewsAction(EventID)(LoginViews)(UserViewsDispatch);
    }

    return {LoginViews, FormData, FormValid, FormSubmit, Loading, Error, Data, FormErrors};
}

export default LoginFunctions;
// API Instance
import AxiosInstance from "../../../../Database/Views/AxiosInstance";

const CountriesAction = (setCountries) => {

    AxiosInstance()
        .get('/countries')
        .then(Response => {
            setCountries(Response.data.data)
        })
        .catch(Error => {
            console.log(Error);
        });

}

export default CountriesAction;
import {useContext, useState} from 'react';

// React Router
import { useHistory } from 'react-router-dom';

// Store Provider
import { DashboardStore } from "../../../Sources/Stores/Dashboard/DashboardStore/DashboardStore";

// Actions
import NewClientAction from "../../../Sources/Actions/Dashboard/NewClientAction/NewClientAction";

const NewClientFunctions = () => {
    const History = useHistory();
    const [NewClient, setNewClient] = useState({});
    const {NewClientState: {NewClient: {Loading, Error, Data}}, NewClientDispatch} = useContext(DashboardStore);

    // Form Format
    const FormFormat = new FormData();
    for (const Data in NewClient) {
        FormFormat.append(`${Data}`, NewClient[Data]);
    }

    // Form Event
    const FormEvent = (event) => {
        const { target: { value, name } } = event;
        setNewClient({...NewClient, [name]: value});
    };

    // Form Validation
    const FormValid =
        !NewClient.logo ||
        !NewClient.name?.length ||
        !NewClient.description?.length

    // Form Submit
    const FormSubmit = () => {
        NewClientAction(History)(FormFormat)(NewClientDispatch);
    }

    return {NewClient, setNewClient, FormEvent, FormValid, FormSubmit, Loading, Error, Data};
}

export default NewClientFunctions;
// Actions Types
import {EDIT_EVENT_LOADING, EDIT_EVENT_SUCCESS, EDIT_EVENT_ERRORS} from "../../Types/Dashboard/EditEventType/EditEventType";

const EditEventReducers = (state, {payload, type}) => {
    switch (type) {
        case EDIT_EVENT_LOADING :
            return {
                ...state,
                EditEvent: {
                    ...state.EditEvent,
                    Loading: true,
                    Error: false
                }
            }
        case EDIT_EVENT_SUCCESS :
            return {
                ...state,
                EditEvent: {
                    ...state.EditEvent,
                    Loading: false,
                    Data: payload,
                }
            }
        case EDIT_EVENT_ERRORS :
            return {
                ...state,
                EditEvent: {
                    ...state.EditEvent,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default EditEventReducers;
import React, {useEffect} from 'react';

// Lodash
import {flattenDeep} from 'lodash';

// Material-UI Components
import {Grid, Button, Typography, RadioGroup, FormControlLabel, Radio, Box} from '@material-ui/core';

// Components
import Questions from "./Questions/Questions";

// Component Style
import useStyles from './SurveyStep_Styles';

const SurveyStep = ({Next, Back, Values, SetValues, Errors, Event}) => {
    const classes = useStyles();
    let X = [];
    let Survey = false;

    Values.survey_questions?.forEach((Ques) => {
        X.push(Ques.question);
        X.push(Ques.answers);
        X = flattenDeep(X);
    })

    if (!(X.includes(""))){
        Survey = true;
    }

    const IsValid = (Values.survey_type) && (!Errors.survey_type) && Survey;

    const SkipAndNext = () => {
        Next();
        delete Values.survey_type;
        delete Values.survey_questions;
    }
    
    useEffect(() => {
        (!Values.survey_questions && Values.survey_type) && SetValues({...Values, survey_questions: [{ question: '', answers:['', '']}]});
    }, [Values, SetValues, Values.survey_questions]);

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography className={classes.titles} variant="h5" display="block" gutterBottom>
                        Survey
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    <RadioGroup row defaultValue="position" name="survey_type" value={Values.survey_type || null} onChange={Event}>
                        <FormControlLabel value="2" control={<Radio />} label="Mandatory" />
                        <FormControlLabel value="1" control={<Radio />} label="Optional" />
                    </RadioGroup>
                </Grid>
                { Values.survey_type &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            {Values.survey_questions?.map((Question, Index) => {
                                return (
                                    <Questions Question={Question} Index={Index} Values={Values} SetValues={SetValues} key={Index}/>
                                )
                            })}
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={classes.box}>
                                <Button variant="contained" color="default" onClick={() => SetValues({...Values, survey_questions: [...Values.survey_questions, {question: '', answers: ['', '']}]})}>
                                    Add New Question
                                </Button>
                            </Box>
                        </Grid>
                    </React.Fragment>
                }
                <Grid item xs={12}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item xs={2}>
                            <Button variant="contained" color="default" onClick={Back}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Button variant="contained" disabled={!IsValid} color="primary" onClick={IsValid ? Next : null}>
                                    Next
                                </Button>
                                <Button variant="contained" color="secondary" onClick={SkipAndNext}>
                                    No Survey & Skip
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default SurveyStep;
// Actions Types
import {DELETE_CLIENT_LOADING, DELETE_CLIENT_SUCCESS, DELETE_CLIENT_ERRORS} from "../../Types/Dashboard/DeleteClientType/DeleteClientType";

const DeleteClientReducers = (state, {payload, type}) => {
    switch (type) {
        case DELETE_CLIENT_LOADING :
            return {
                ...state,
                DeleteClient: {
                    ...state.DeleteClient,
                    Loading: true,
                    Error: false
                }
            }
        case DELETE_CLIENT_SUCCESS :
            return {
                ...state,
                DeleteClient: {
                    ...state.DeleteClient,
                    Loading: false,
                    Data: payload,
                }
            }
        case DELETE_CLIENT_ERRORS :
            return {
                ...state,
                DeleteClient: {
                    ...state.DeleteClient,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default DeleteClientReducers;
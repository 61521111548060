// API Instance
import AxiosInstance from "../../../../Database/Views/AxiosInstance";

// Actions Types
import {USER_LOADING, USER_SUCCESS, USER_ERRORS} from "../../../Types/Views/UserViewsType/UserViewsType";

const UserInfoViewsAction = (UserViewsDispatch) => {
    UserViewsDispatch({
        type: USER_LOADING,
    })

    AxiosInstance()
        .get(`/profile`)
        .then(Response => {
            UserViewsDispatch({
                type: USER_SUCCESS,
                payload: Response.data.data,
            })
        })
        .catch(Error => (
            UserViewsDispatch({
                type: USER_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
        ));
}

export default UserInfoViewsAction;
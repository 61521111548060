import React, {useEffect, useState, useContext} from 'react';

// Operations
import EditEventFunction from "../../../../Functions/Dashboard/EditEvent/EditEventFunction";

// Actions
import ClientsAction from "../../../../Sources/Actions/Dashboard/ClientsAction/ClientsAction";
import ShowEventAction from "../../../../Sources/Actions/Dashboard/ShowEventAction/ShowEventAction";

// React Router
import { useParams } from 'react-router-dom';

// Stores
import { DashboardStore } from "../../../../Sources/Stores/Dashboard/DashboardStore/DashboardStore";

// Material-UI Components
import {Typography, Paper, Grid, Stepper, Box, Button, CircularProgress, Step, StepLabel} from '@material-ui/core';

// Material-UI Icons
import { Save } from '@material-ui/icons';

// Steps Components
import EventStep from './EditEventSteps/EventStep/EventStep';
import SurveyStep from './EditEventSteps/SurveyStep/SurveyStep';
import CertificateStep from './EditEventSteps/CertificateStep/CertificateStep';

// Component Style
import useStyles from './EditEventLayout_Styles';

const EditEventLayout = () => {
    const classes = useStyles();
    const {EventID} = useParams();
    const [ActiveStep, setActiveStep] = useState(0);
    const {ClientsState: {Clients: {Data}}, ClientsDispatch, ShowEventState: {ShowEvent: {Data: EventData}}, ShowEventDispatch} = useContext(DashboardStore);
    const {FormValues, setFormValues, FormErrors, FormEvent, FormValid, FormSubmit, Loading, Error} = EditEventFunction();

    // Step titles
    const Labels = ["Event", "Survey", "Certificate"];

    // Proceed To Next Step
    const NextStep = () => {
        setActiveStep((prev) => prev + 1)
    };

    // Go Back To Prev Step
    const BackStep = () => {
        setActiveStep((prev) => prev - 1)
    };

    useEffect(() => {
        ShowEventAction(EventID)(ShowEventDispatch);
    }, [EventID, ShowEventDispatch]);

    // Get Clients
    useEffect(() => {
        ClientsAction(ClientsDispatch);
    }, [ClientsDispatch])

    const StepsContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <EventStep Next={NextStep} Values={FormValues} SetValues={setFormValues} Errors={FormErrors} Event={FormEvent} EventData={EventData} DataError={Error} Clients={Data} />
                );
            case 1:
                return (
                    <SurveyStep Next={NextStep} Back={BackStep} Values={FormValues} SetValues={setFormValues} Errors={FormErrors} EventData={EventData} Event={FormEvent} DataError={Error} />
                );
            case 2:
                return (
                    <CertificateStep Back={BackStep} Values={FormValues} SetValues={setFormValues} Errors={FormErrors} Event={FormEvent} EventData={EventData} DataError={Error} />
                );
            default:
                break;
        }
    };

    return (
        <React.Fragment>
            <Box className={classes.header}>
                <Typography className={classes.title} variant="h5" gutterBottom>
                    Edit Event
                    <Box component={'span'}>Edit Event in CME Certificate System.</Box>
                </Typography>
                <Button startIcon={<Save />} onClick={FormSubmit} checked={false} disabled={FormValid || Loading} variant="contained" disableElevation color="primary">
                    {(Loading) ? <CircularProgress /> : <React.Fragment>Save</React.Fragment>}
                </Button>
            </Box>
            {(Object.keys(EventData).length !== 0) &&
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stepper activeStep={ActiveStep} alternativeLabel>
                                {Labels.map((Label) => (
                                    <Step key={Label}>
                                        <StepLabel>{Label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                    </Grid>
                    <Paper elevation={3} className={classes.root}>
                        {StepsContent(ActiveStep)}
                    </Paper>
                </form>
            }
        </React.Fragment>
    );
};

export default EditEventLayout;
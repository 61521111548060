// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Actions Types
import {SHOW_CLIENT_LOADING, SHOW_CLIENT_SUCCESS, SHOW_CLIENT_ERRORS} from "../../../Types/Dashboard/ShowClientType/ShowClientType";

const ShowClientAction = (ClientID) => (ShowClientDispatch) => {

    ShowClientDispatch({
        type: SHOW_CLIENT_LOADING
    });

    AxiosInstance()
        .get(`/dashboard/clients/${ClientID}`)
        .then(Response => {
            ShowClientDispatch({
                type: SHOW_CLIENT_SUCCESS,
                payload: Response.data.data
            });
        })
        .catch(Error => (
            ShowClientDispatch({
                type: SHOW_CLIENT_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API'
            })
        ));
}

export default ShowClientAction;
import React from 'react';

// React Router
import {Link as LinkRouter} from 'react-router-dom';

// Operations
import LoginFunctions from "../../../../Functions/Dashboard/LoginFunctions/LoginFunctions";

// Material-UI Components
import { Grid, Box, Typography, TextField, Button, CircularProgress, Link } from '@material-ui/core';

// Component Style
import useStyles from './LoginLayout_Styles';

const LoginLayout = () => {
    const classes = useStyles();
    const {LoginDashboard, FormData, FormValid, FormSubmit, Loading, Error, FormErrors} = LoginFunctions();

    window.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            document.getElementById("Submit").click();
        }
    });

    const FormErrorsCheck = !!FormErrors.email ||
                            !!FormErrors.password;

    return (
        <React.Fragment>
            <Grid container spacing={0} className={classes.root}>
                <Grid item md={6} className={classes.welcomeCont}>
                    <Typography variant="h3" gutterBottom>
                        Welcome To
                    </Typography>
                    <Typography variant="h2" gutterBottom>
                        CME System
                    </Typography>
                </Grid>

                <Grid item md={6} className={classes.formCont}>
                    <Box className={classes.formTitle}>
                        <Typography variant="h3" gutterBottom>
                            Login
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Please enter your email and password
                        </Typography>
                    </Box>
                    <form noValidate autoComplete="off" className={classes.form} >
                        <TextField className={classes.input} value={LoginDashboard.email || ''} onChange={FormData} type="email" name="email" label="Email" variant="outlined" error={!!FormErrors.email} helperText={(Error && Error.errors.email) || FormErrors.email} />
                        <TextField className={classes.input} value={LoginDashboard.password || ''} onChange={FormData} type="password" name="password" label="Password" variant="outlined" error={!!FormErrors.password} helperText={(Error && Error.errors.password) || FormErrors.password} />
                        <Typography gutterBottom>
                            <Link component={LinkRouter} to={'./forgot-password'}>
                                Forgot Password ?
                            </Link>
                        </Typography>
                        <Button onClick={FormSubmit} id="Submit" disabled={FormValid || Loading || FormErrorsCheck} variant="contained" disableElevation className={classes.submit}>
                            {(Loading)? <CircularProgress /> : <React.Fragment>Login</React.Fragment>}
                        </Button>
                    </form>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default LoginLayout;
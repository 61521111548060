// Actions Types
import {CLIENT_INFO_LOADING, CLIENT_INFO_SUCCESS, CLIENT_INFO_ERRORS} from "../../Types/Dashboard/ClientInfoType/ClientInfoType";

const ClientInfoReducers = (state, {payload, type}) => {
    switch (type) {
        case CLIENT_INFO_LOADING :
            return {
                ...state,
                ClientInfo: {
                    ...state.ClientInfo,
                    Loading: true,
                    Error: false
                }
            }
        case CLIENT_INFO_SUCCESS :
            return {
                ...state,
                ClientInfo: {
                    ...state.ClientInfo,
                    Loading: false,
                    Data: payload,
                }
            }
        case CLIENT_INFO_ERRORS :
            return {
                ...state,
                ClientInfo: {
                    ...state.ClientInfo,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default ClientInfoReducers;
import React from 'react';

// Operations
import NewClientFunction from "../../../../Functions/Dashboard/NewClient/NewClientFunction";

// Material-UI Components
import {Typography, Paper, Grid, TextField, Box, Button, CircularProgress} from '@material-ui/core';

// Material-UI Icons
import { Save } from '@material-ui/icons';

// Image Uploader
import { DropzoneArea } from 'material-ui-dropzone';

// Component Style
import useStyles from './NewClientLayout_Styles';

const NewClientLayout = () => {
    const classes = useStyles();
    const {NewClient, setNewClient, FormEvent, FormValid, FormSubmit, Loading, Error} = NewClientFunction();

    window.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            document.getElementById("Submit").click();
        }
    });

    const deleteFromState = (DelValue) => {
        const { [DelValue]: value, ...reset } = NewClient;
        setNewClient(reset);
    }

    return (
        <React.Fragment>
            <Box className={classes.header}>
                <Typography className={classes.title} variant="h5" gutterBottom>
                    New Client
                    <Box component={'span'}>Create New Client in CME Certificate System.</Box>
                </Typography>
                <Button startIcon={<Save />} onClick={FormSubmit} checked={false} disabled={FormValid || Loading} variant="contained" disableElevation color="primary">
                    {(Loading) ? <CircularProgress /> : <React.Fragment>Save</React.Fragment>}
                </Button>
            </Box>
            <Paper elevation={3} className={classes.root}>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid className={classes.dropzone} item lg={3} md={5} xs={12}>
                            <DropzoneArea showAlerts={['error']} maxFileSize={5000000} inputProps= {{name: "logo"}} filesLimit={1} acceptedFiles={['image/*']} onDelete={() => deleteFromState('logo')} onChange={(files) => files[0] && setNewClient({...NewClient, logo: files[0]})} dropzoneText={"Upload Client Logo"} />
                            {(Error && Error.errors.logo) &&
                                <Typography variant="caption" display="block" color={"error"} gutterBottom>
                                    {Error.errors.logo}
                                </Typography>
                            }
                        </Grid>
                        <Grid item lg={5} md={7} xs={12}>
                            <TextField fullWidth value={NewClient.name || ''} className={classes.input} onChange={FormEvent} type="text" name="name" label="Client Name" variant="outlined" helperText={(Error && Error.errors) && Error.errors.name} />
                            <TextField multiline value={NewClient.description || ''} rows={8} fullWidth className={classes.input} onChange={FormEvent} type="text" name="description" label="Description" variant="outlined" helperText={(Error && Error.errors) && Error.errors.description} />
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </React.Fragment>
    );
};

export default NewClientLayout;
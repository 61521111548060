// Actions Types
import {EVENT_USERS_LOADING, EVENT_USERS_SUCCESS, EVENT_USERS_ERRORS} from "../../Types/Dashboard/EventUsersType/EventUsersType";

const EventUsersReducers = (state, {payload, type}) => {
    switch (type) {
        case EVENT_USERS_LOADING :
            return {
                ...state,
                EventUsers: {
                    ...state.EventUsers,
                    Loading: true,
                    Error: false
                }
            }
        case EVENT_USERS_SUCCESS :
            return {
                ...state,
                EventUsers: {
                    ...state.EventUsers,
                    Loading: false,
                    Data: payload,
                }
            }
        case EVENT_USERS_ERRORS :
            return {
                ...state,
                EventUsers: {
                    ...state.EventUsers,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default EventUsersReducers;
// Actions Types
import {LOGOUT_LOADING, LOGOUT_SUCCESS, LOGOUT_ERRORS} from "../../Types/Dashboard/LogoutDashboardType/LogoutDashboardType";

const LogoutDashboardReducers = (state, {payload, type}) => {
    switch (type) {
        case LOGOUT_LOADING :
            return {
                ...state,
                LogoutDashboard: {
                    ...state.LogoutDashboard,
                    Loading: true,
                    Error: false
                }
            }
        case LOGOUT_SUCCESS :
            return {
                ...state,
                LogoutDashboard: {
                    ...state.LogoutDashboard,
                    Loading: false,
                    Data: payload,
                }
            }
        case LOGOUT_ERRORS :
            return {
                ...state,
                LogoutDashboard: {
                    ...state.LogoutDashboard,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default LogoutDashboardReducers;
import React, {useContext} from 'react';

// Store Provider
import { ViewsStore } from "../../../Sources/Stores/Views/ViewsStore/ViewsStore";

// Operations
import ProfileFunctions from "../../../Functions/Views/ProfileFunctions/ProfileFunctions";

// Material-UI Components
import { Grid, Box, Typography, TextField, Button, MenuItem, CircularProgress } from '@material-ui/core';

// Component Style
import useStyles from './ProfileLayout_Styles';

const ProfileLayout = (props) => {
    const Event = props.event;
    const classes = useStyles();
    const { Countries } = useContext(ViewsStore);
    const { ProfileViews, FormData, FormValid, FormSubmit, Loading, Error, Cities, FormErrors } = ProfileFunctions();

    window.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            document.getElementById("Submit").click();
        }
    });

    const FormErrorsCheck = !!FormErrors.first_name ||
                            !!FormErrors.last_name ||
                            !!FormErrors.mobile ||
                            !!FormErrors.national_id ||
                            !!FormErrors.speciality;

    // Characters
    const CharValid = (event) => {
        const Regex = new RegExp("[a-zA-Z ]")
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (!Regex.test(keyValue)) {
            event.preventDefault();
        }
    }

    // Numbers
    const NumValid = (event) => {
        const Regex = new RegExp("[0-9]")
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (!Regex.test(keyValue)) {
            event.preventDefault();
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={0} className={classes.root}>
                <Grid item md={6} className={classes.welcomeCont} style={{backgroundColor: `${Event.main_color}`}}>
                    <Typography variant="h3" gutterBottom style={{color: `${Event.font_color}`}}>
                        Welcome To
                    </Typography>
                    <Typography variant="h2" gutterBottom style={{color: `${Event.font_color}`}}>
                        {Event.name}
                    </Typography>
                </Grid>

                <Grid item md={6} className={classes.formCont}>
                    <Box className={classes.formTitle}>
                        <Typography variant="h3" gutterBottom style={{color: `${Event.title_color}`}}>
                            Profile
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Please fill out the following fields
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            Please note that the name provided here will be the one stated on your certificate.
                        </Typography>
                    </Box>
                    <form noValidate autoComplete="off" className={classes.form} >
                        <TextField className={classes.input} value={ProfileViews.first_name || ''} onKeyPress={CharValid} onChange={FormData} type="text" name="first_name" label="First Name" variant="outlined" error={!!FormErrors.first_name} helperText={(Error.errors && Error.errors?.first_name) || FormErrors.first_name} />
                        <TextField className={classes.input} value={ProfileViews.last_name || ''} onKeyPress={CharValid} onChange={FormData} type="text" name="last_name" label="Last Name" variant="outlined" error={!!FormErrors.last_name} helperText={(Error.errors && Error.errors?.last_name) || FormErrors.last_name} />
                        <TextField className={classes.input} value={ProfileViews.mobile || ''} onKeyPress={NumValid} onChange={FormData} type="text" name="mobile" label="Mobile Number" variant="outlined" error={!!FormErrors.mobile} helperText={(Error.errors && Error.errors?.mobile) || FormErrors.mobile} />
                        <TextField className={classes.input} value={ProfileViews.national_id || ''} onChange={FormData} type="text" name="national_id" label="National ID Number" variant="outlined" error={!!FormErrors.national_id} helperText={(Error.errors && Error.errors?.national_id) || FormErrors.national_id} />
                        <TextField className={classes.input} value={ProfileViews.speciality || ''} onKeyPress={CharValid} onChange={FormData} type="text" name="speciality" label="Specialty" variant="outlined" error={!!FormErrors.speciality} helperText={(Error.errors && Error.errors?.speciality) || FormErrors.speciality} />
                        <TextField className={classes.input} value={ProfileViews.country_id || ''} onChange={FormData} select name="country_id" label="Country" variant="outlined" error={!!FormErrors.country_id} helperText={(Error.errors && Error.errors?.country_id) || FormErrors.country_id}>
                            {Countries.length ? Countries.map((country) => (
                                <MenuItem key={country.id} value={country.id}>
                                    {country.name}
                                </MenuItem>))
                                :
                                <MenuItem disabled>No Countries Available</MenuItem>
                            }
                        </TextField>
                        <TextField className={classes.input} value={ProfileViews.city_id || ''} onChange={FormData} select name="city_id" label="City" variant="outlined" error={!!FormErrors.city_id} helperText={(Error.errors && Error.errors?.city_id) || FormErrors.city_id}>
                            {Cities.length ? Cities.map((city) => (
                                <MenuItem key={city.id} value={city.id}>
                                    {city.name}
                                </MenuItem>))
                                :
                                <MenuItem disabled>No Cities Available</MenuItem>
                            }
                        </TextField>
                        <Button onClick={FormSubmit} id="Submit" disabled={FormValid || Loading || FormErrorsCheck} variant="contained" disableElevation className={classes.submit} style={{backgroundColor: `${Event.main_color}`}}>
                            {(Loading)? <CircularProgress /> : <React.Fragment>Submit</React.Fragment>}
                        </Button>
                    </form>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ProfileLayout;
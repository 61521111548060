// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Actions Types
import {EVENT_USERS_LOADING, EVENT_USERS_SUCCESS, EVENT_USERS_ERRORS} from "../../../Types/Dashboard/EventUsersType/EventUsersType";

const EventUsersAction = (EventHash) => (EventUsersDispatch) => {

    EventUsersDispatch({
        type: EVENT_USERS_LOADING
    });

    AxiosInstance()
        .get(`/dashboard/events/${EventHash}/users`)
        .then(Response => {
            EventUsersDispatch({
                type: EVENT_USERS_SUCCESS,
                payload: Response.data.data
            });
        })
        .catch(Error => (
            EventUsersDispatch({
                type: EVENT_USERS_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API'
            })
        ));
}

export default EventUsersAction;
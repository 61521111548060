// Actions Types
import {EVENT_VIEW_LOADING, EVENT_VIEW_SUCCESS, EVENT_VIEW_ERRORS} from "../../Types/Views/EventViewsType/EventViewsType";

const EventViewsReducers = (state, {payload, type}) => {
    switch (type) {
        case EVENT_VIEW_LOADING :
            return {
                ...state,
                EventViews: {
                    ...state.EventViews,
                    Loading: true,
                    Error: false
                }
            }
        case EVENT_VIEW_SUCCESS :
            return {
                ...state,
                EventViews: {
                    ...state.EventViews,
                    Loading: false,
                    Data: payload,
                }
            }
        case EVENT_VIEW_ERRORS :
            return {
                ...state,
                EventViews: {
                    ...state.EventViews,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default EventViewsReducers;
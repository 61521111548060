import {useContext, useState} from 'react';

// React Router
import { useHistory, useParams } from 'react-router-dom';

// Store Provider
import { DashboardStore } from "../../../Sources/Stores/Dashboard/DashboardStore/DashboardStore";

// Actions
import EditClientAction from "../../../Sources/Actions/Dashboard/EditClientAction/EditClientAction";

const EditClientFunctions = () => {
    const History = useHistory();
    const {ClientID} = useParams();
    const [EditClient, setEditClient] = useState({});
    const {EditClientState: {EditClient: {Loading, Error, Data}}, EditClientDispatch} = useContext(DashboardStore);

    // Form Format
    const FormFormat = new FormData();
    for (const Data in EditClient) {
        FormFormat.append(`${Data}`, EditClient[Data]);
    }

    // Form Event
    const FormEvent = (event) => {
        const { target: { value, name } } = event;
        setEditClient({...EditClient, [name]: value});
    };

    // Form Validation
    const FormValid =
        !EditClient.logo &&
        !EditClient.name?.length &&
        !EditClient.description?.length

    // Form Submit
    const FormSubmit = () => {
        EditClientAction(History)(ClientID)(FormFormat)(EditClientDispatch);
    }

    return {EditClient, setEditClient, FormEvent, FormValid, FormSubmit, Loading, Error, Data};
}

export default EditClientFunctions;
import React from 'react';

// Material-UI Components
import {Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography} from '@material-ui/core';

// Component Style
import useStyles from "./QuestionLayout_Styles";

const QuestionLayout = ({Style, Question, Number, FormValues, setFormValues}) => {
    const classes = useStyles();

    const FormData = (event) => {
        const { target: { value, name } } = event;
        setFormValues({...FormValues, [name]: value});
    };

    return (
        <React.Fragment>
            <Box className={classes.question}>
                <FormControl fullWidth component="fieldset">
                    <FormLabel component="legend">
                        <Typography className={classes.qNumber} style={{color: Style.title_color}} variant="subtitle1" gutterBottom>
                            Q{Number + 1}
                        </Typography>
                        {Question.content}
                    </FormLabel>
                    <RadioGroup row aria-label="question" name={`answers[${Question.id}]`} value={FormValues[`answers[${Question?.id}]`] || null} onChange={FormData}>
                        {Question?.answers?.map((Answer, Index) => {
                            return <FormControlLabel key={Index} value={`${Answer.id}`} control={<Radio />} label={`${Answer.content}`} />
                        })}
                    </RadioGroup>
                </FormControl>
            </Box>
        </React.Fragment>
    );
};

export default QuestionLayout;
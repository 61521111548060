// Regex Patterns
const IsNumber = RegExp(/^\d+$/);
const IsText = RegExp(/^[A-Z ]+$/i);
const IsZip = RegExp(/^[0-9]{5}([- /]?[0-9]{4})?$/);
const IsPhone = RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4,6})$/);
const IsEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);

// Validation Function
const FormsValidations = (Name, Value, Schema) => {
    const { validate, minLength, maxLength } = Schema[Name];
    let Error = "";

    if (typeof Value === 'string') {
        Value = Value.trim();
    }

    (minLength && Value.length < minLength) && (Error = `Minimum ${minLength} characters is required.`);
    (maxLength && Value.length > maxLength) && (Error = `Maximum length of ${maxLength} exceeded!`);

    switch (validate) {
        case "text":
            if (!IsText.test(Value)) Error = "This field accept text only.";
            break;

        case "number":
            if (!IsNumber.test(Value)) Error = "This field accept numbers only.";
            break;

        case "email":
            if (!IsEmail.test(Value)) Error = "Please enter a valid email";
            break;

        case "phone":
            if (!IsPhone.test(Value))
                Error = "Please enter a valid phone number. i.e: xxx-xxx-xxxx";
            break;

        case "zip":
            if (!IsZip.test(Value)) Error = "Please enter a valid zip code";
            break;

        case "checkbox":
            if (!Value) Error = "Please select a Value";
            break;

        default:
            break;
    }

    return Error;
}

export default FormsValidations;
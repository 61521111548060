import React from 'react';

// React Router
import {Switch, Route} from 'react-router-dom';

// Material-UI Components
import { Box } from '@material-ui/core';

// Components
import DashboardBody from "../../../Components/Dashboard/DashboardBody/DashboardBody";
import DashboardHeader from "../../../Components/Dashboard/DashboardHeader/DashboardHeader";
import DashboardSideMenu from "../../../Components/Dashboard/DashboardSideMenu/DashboardSideMenu";

// Layouts
import AnalysisLayout from "../../../Layouts/Dashboard/Analysis/Analysis/AnalysisLayout";
import NewClientLayout from "../../../Layouts/Dashboard/Clients/NewClient/NewClientLayout";
import EditClientLayout from "../../../Layouts/Dashboard/Clients/EditClient/EditClientLayout";
import ClientInfoLayout from "../../../Layouts/Dashboard/Clients/ClientInfo/ClientInfoLayout";
import ClientsListLayout from "../../../Layouts/Dashboard/Clients/ClientsList/ClientsListLayout";
import NewEventLayout from "../../../Layouts/Dashboard/Events/NewEvent/NewEventLayout";
import EditEventLayout from "../../../Layouts/Dashboard/Events/EditEvent/EditEventLayout";
import EventInfoLayout from "../../../Layouts/Dashboard/Events/EventInfo/EventInfoLayout";
import EventsListLayout from "../../../Layouts/Dashboard/Events/EventsList/EventsListLayout";

const DashboardContainer = () => {
    return (
        <React.Fragment>
            <Box display="flex">
                <DashboardHeader />
                <DashboardSideMenu />
                <DashboardBody>
                    <Switch>
                        {/* Dashboard */}
                        <Route exact path="/dashboard" component={AnalysisLayout} />

                        {/* Clients */}
                        <Route exact path="/dashboard/clients" component={ClientsListLayout} />
                        <Route exact path="/dashboard/clients/create" component={NewClientLayout} />
                        <Route exact path="/dashboard/clients/:ClientID" component={ClientInfoLayout} />
                        <Route exact path="/dashboard/clients/:ClientID/edit" component={EditClientLayout} />

                        {/* Events */}
                        <Route exact path="/dashboard/events" component={EventsListLayout} />
                        <Route exact path="/dashboard/events/create" component={NewEventLayout} />
                        <Route exact path="/dashboard/events/:EventID" component={EventInfoLayout} />
                        <Route exact path="/dashboard/events/:EventID/edit" component={EditEventLayout} />
                    </Switch>
                </DashboardBody>
            </Box>
        </React.Fragment>
    );
};

export default DashboardContainer;
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '20px 25px',
        marginBottom: '15px',
        border: '1px solid #c4c4c4',
    },
    titleContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    titles: {
        margin: 0,
        fontSize: '18px',
        fontWeight: '400',
    },
}));

export default useStyles;
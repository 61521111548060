// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Actions Types
import {LOGIN_ERRORS, LOGIN_LOADING, LOGIN_SUCCESS} from "../../../Types/Dashboard/LoginDashboardType/LoginDashboardType";

const LoginDashboardAction = (History) => ({email, password}) => (LoginDashboardDispatch) => {

    LoginDashboardDispatch({
        type: LOGIN_LOADING,
    })

    AxiosInstance()
        .post(`/dashboard/login`, {email, password})
        .then(Response => {
            localStorage.Q8YS3VHAGQXSP8H4G = Response.data.token.access_token;
            LoginDashboardDispatch({
                type: LOGIN_SUCCESS,
                payload: Response.data,
            });
            History.push('/dashboard');
        })
        .catch(Error => (
            LoginDashboardDispatch({
                type: LOGIN_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
        ));

}

export default LoginDashboardAction;
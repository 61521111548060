// Actions Types
import {ADD_EVENT_USERS_LOADING, ADD_EVENT_USERS_SUCCESS, ADD_EVENT_USERS_ERRORS} from "../../Types/Dashboard/AddEventUsersType/AddEventUsersType";

const AddEventUsersReducers = (state, {payload, type}) => {
    switch (type) {
        case ADD_EVENT_USERS_LOADING :
            return {
                ...state,
                AddEventUsers: {
                    ...state.AddEventUsers,
                    Loading: true,
                    Error: false
                }
            }
        case ADD_EVENT_USERS_SUCCESS :
            return {
                ...state,
                AddEventUsers: {
                    ...state.AddEventUsers,
                    Loading: false,
                    Data: payload,
                }
            }
        case ADD_EVENT_USERS_ERRORS :
            return {
                ...state,
                AddEventUsers: {
                    ...state.AddEventUsers,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default AddEventUsersReducers;
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    question: {
        width: '100%',
        marginTop: '30px',
        '& .MuiFormLabel-root': {
            fontSize: '20px',
            textAlign: 'left',
        }
    },
    qNumber: {
        display: 'inline',
        fontSize: '20px',
        marginRight: '15px',
    }
}));

export default useStyles;
// Actions Types
import {SHOW_CLIENT_LOADING, SHOW_CLIENT_SUCCESS, SHOW_CLIENT_ERRORS} from "../../Types/Dashboard/ShowClientType/ShowClientType";

const ShowClientReducers = (state, {payload, type}) => {
    switch (type) {
        case SHOW_CLIENT_LOADING :
            return {
                ...state,
                ShowClient: {
                    ...state.ShowClient,
                    Loading: true,
                    Error: false
                }
            }
        case SHOW_CLIENT_SUCCESS :
            return {
                ...state,
                ShowClient: {
                    ...state.ShowClient,
                    Loading: false,
                    Data: payload,
                }
            }
        case SHOW_CLIENT_ERRORS :
            return {
                ...state,
                ShowClient: {
                    ...state.ShowClient,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default ShowClientReducers;
import React, {useEffect, useState} from 'react';

// Time Formatting
import Moment from 'moment';

// Material-UI Components
import {Grid, Button, Typography, TextField} from '@material-ui/core';

// Image Uploader
import {DropzoneArea} from 'material-ui-dropzone';

// Component Style
import useStyles from './CertificateStep_Styles';

const CertificateStep = ({Back, Values, SetValues, Errors, Event, DataError}) => {
    const classes = useStyles();
    const TodayDate = new Date();
    const NextDate = new Date();
          NextDate.setDate(NextDate.getDate() + 1);
    const [ DateError, setDateError ] = useState('');
    const StartDate = Moment(TodayDate).format('YYYY-MM-DD');
    const EndDate = Moment(NextDate).format('YYYY-MM-DD');

    const deleteFromState = (DelValue) => {
        const { [DelValue]: value, ...reset } = Values;
        SetValues(reset);
    }

    useEffect(() => {
        if (!Values.start_date) {
            SetValues((Values) => ({...Values, start_date: StartDate}));
        }
        if (!Values.end_date) {
            SetValues((Values) => ({...Values, end_date: EndDate}));
        }
    }, [SetValues, Values.end_date, Values.start_date, StartDate, EndDate]);

    useEffect(() => {
        if (DateError === '') {
            SetValues((Values) => ({...Values, date: '0'}))
        } else {
            SetValues((Values) => ({...Values, date: '1'}))
        }
    }, [SetValues, DateError])

    useEffect(() => {
        const Now = new Date();
              Now.setDate(Now.getDate() - 1);
              Now.getTime();
        const Start = new Date (Values.start_date).getTime();
        const End = new Date (Values.end_date).getTime();

        if (End < Start) {
            setDateError('Start Date Should Be Before or Equal End Date')
        } else if (Start < Now) {
            setDateError('Start Date Should Be Today Or After Today')
        } else {
            setDateError('')
        }
    }, [setDateError, Values.end_date, Values.start_date]);

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography className={classes.titles} variant="h5" display="block" gutterBottom>
                        Certificate
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField fullWidth value={Values.start_date || StartDate} className={classes.input} onChange={Event} type="date" name="start_date" label="Start Date" variant="outlined" error={!!Errors.start_date || !(DateError === '')} helperText={Errors.start_date || DateError} required />
                    {(DataError && DataError.errors && !Errors.start_date) &&
                        <Typography variant="caption" display="block" color={"error"} gutterBottom>
                            {DataError.errors.start_date}
                        </Typography>
                    }
                    <TextField fullWidth value={Values.end_date || EndDate} className={classes.input} onChange={Event} type="date" name="end_date" label="End Date" variant="outlined" required />
                    {(DataError && DataError.errors && !Errors.end_date) &&
                        <Typography variant="caption" display="block" color={"error"} gutterBottom>
                            {DataError.errors.end_date}
                        </Typography>
                    }
                </Grid>
                <Grid className={classes.dropzone} item md={3} xs={6}>
                    <DropzoneArea {...!!Values.certificate && {initialFiles: [Values.certificate]}} showAlerts={['error']} maxFileSize={5000000} inputProps={{name: "certificate"}} filesLimit={1} acceptedFiles={['image/*']} onDelete={() => deleteFromState('certificate')} onChange={(files) => files[0] && SetValues({...Values, certificate: files[0]})} dropzoneText={"Upload Certificate"} />
                    {(DataError && DataError.errors && !Errors.certificate) &&
                        <Typography variant="caption" display="block" color={"error"} gutterBottom>
                            {DataError.errors.certificate}
                        </Typography>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="default" onClick={Back}>
                        Back
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default CertificateStep;
// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Actions
import EventsAction from "../EventsAction/EventsAction";

// Notifications
import {toast} from 'react-toastify';

// Actions Types
import {ADD_EVENT_USERS_LOADING, ADD_EVENT_USERS_SUCCESS, ADD_EVENT_USERS_ERRORS} from "../../../Types/Dashboard/AddEventUsersType/AddEventUsersType";

const AddEventUsersAction = (EventHash) => (AddEventUsersDispatch) => (AttendantsEmails) => (setGridData) => (setEventUsersModal) => (EventsDispatch) => (setAttendantsEmails) => {

    AddEventUsersDispatch({
        type: ADD_EVENT_USERS_LOADING
    });

    AxiosInstance()
        .post(`/dashboard/events/${EventHash}/users`, {users: AttendantsEmails})
        .then(Response => {
            setGridData([]);
            setEventUsersModal(false);
            EventsAction(EventsDispatch);
            setAttendantsEmails([]);
            AddEventUsersDispatch({
                type: ADD_EVENT_USERS_SUCCESS,
                payload: Response.data.data
            });
            toast('New Users has been added successful!', {
                autoClose: 3000,
                draggable: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                position: "top-right",
                hideProgressBar: false,
            });
        })
        .catch(Error => (
            AddEventUsersDispatch({
                type: ADD_EVENT_USERS_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API'
            })
        ));
}

export default AddEventUsersAction;
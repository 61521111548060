// API Instance
import AxiosInstance from "../../../../Database/Views/AxiosInstance";

// Actions Types
import {SURVEY_LOADING, SURVEY_SUCCESS, SURVEY_ERRORS} from "../../../Types/Views/SurveyType/SurveyType";

const SurveyAction = (History) => (SurveyID) => (FormFormat) => (SurveyDispatch) => {

    SurveyDispatch({
        type: SURVEY_LOADING,
    })

    AxiosInstance()
        .post(`/surveys/${SurveyID}/answers`, (FormFormat))
        .then(Response => {
            SurveyDispatch({
                type: SURVEY_SUCCESS,
                payload: Response.data.data,
            })
            History.push('./certificate');
        })
        .catch(Error => (
            SurveyDispatch({
                type: SURVEY_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
        ));

}

export default SurveyAction;
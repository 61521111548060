import {useContext, useState} from 'react';

// React Router
import { useHistory } from 'react-router-dom';

// Store Provider
import { DashboardStore } from "../../../Sources/Stores/Dashboard/DashboardStore/DashboardStore";

// Actions
import ForgotPasswordDashboardAction from "../../../Sources/Actions/Dashboard/ForgotPasswordDashboardAction/ForgotPasswordDashboardAction";
import FormValidation from "../../Validation/FormsValidations";

const ForgotPasswordFunctions = () => {
    const History = useHistory();
    const [FormErrors, setFormErrors] = useState({});
    const [ForgotPasswordDashboard, setForgotPasswordDashboard] = useState({});
    const {ForgotPasswordDashboardState: {ForgotPasswordDashboard: {Loading, Error, Data}}, ForgotPasswordDashboardDispatch} = useContext(DashboardStore);

    // Fields Validation
    const FieldsValidation = {
        email : {
            error: "",
            validate: 'email',
        },
    };

    // Get Form Data
    const FormData = (event) => {
        const { target: { value, name } } = event;
        setForgotPasswordDashboard({...ForgotPasswordDashboard, [name]: value});

        // Set Errors
        const error = FormValidation(name, value, FieldsValidation) || '';
        setFormErrors({...FormErrors, [name]: error});
    };

    // Form Validation
    const FormValid =
        !ForgotPasswordDashboard.email?.length

    // Form Submit
    const FormSubmit = () => {
        ForgotPasswordDashboardAction(History)(ForgotPasswordDashboard)(ForgotPasswordDashboardDispatch);
    }

    return {ForgotPasswordDashboard, FormData, FormValid, FormSubmit, Loading, Error, Data, FormErrors};
}

export default ForgotPasswordFunctions;
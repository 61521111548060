// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Actions Types
import {LOGOUT_ERRORS, LOGOUT_LOADING, LOGOUT_SUCCESS} from "../../../Types/Dashboard/LogoutDashboardType/LogoutDashboardType";

const LogoutDashboardAction = (History) => (LogoutDashboardDispatch) => {

    LogoutDashboardDispatch({
        type: LOGOUT_LOADING,
    })

    AxiosInstance()
        .post(`/dashboard/logout`)
        .then(Response => {
            LogoutDashboardDispatch({
                type: LOGOUT_SUCCESS,
                payload: Response.data,
            });
            localStorage.removeItem('Q8YS3VHAGQXSP8H4G');
            History.push('/auth/login');
        })
        .catch(Error => (
            LogoutDashboardDispatch({
                type: LOGOUT_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
        ));

}

export default LogoutDashboardAction;
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root:{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        backgroundSize: 'cover',
        backgroundPosition:'center',
        backgroundRepeat:'no-repeat',
    },

}));

export default useStyles;
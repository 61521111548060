import React, {useContext, useEffect} from 'react';

// Sweet Alert
import Alert from 'sweetalert2';

// React Router
import { Link } from 'react-router-dom';

// Stores
import { DashboardStore } from "../../../../Sources/Stores/Dashboard/DashboardStore/DashboardStore";

// Actions
import ClientsAction from "../../../../Sources/Actions/Dashboard/ClientsAction/ClientsAction";
import DeleteClientAction from "../../../../Sources/Actions/Dashboard/DeleteClientAction/DeleteClientAction";

// Material-UI Components
import { Box, Typography, Table, TableHead, TableBody, TableRow, TableCell, Button } from '@material-ui/core';

// Material-UI Icons
import { Delete, Edit, AddCircle } from '@material-ui/icons';

// Component Style
import useStyles from './ClientsListLayout_Styles';

const ClientsListLayout = () => {
    const classes = useStyles();
    const {ClientsState: {Clients: {Data}}, ClientsDispatch, DeleteClientDispatch} = useContext(DashboardStore);

    // Get Conferences
    useEffect(() => {
        ClientsAction(ClientsDispatch);
    }, [ClientsDispatch]);

    // Delete Client
    const DeleteClient = (ClientID) => {
        Alert.fire({
            title: 'Are you sure?',
            text : 'You will not be able to recover this client!',
            icon : 'warning',
            showCancelButton : true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText : 'No, keep it',
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteClientAction(ClientID)(ClientsDispatch)(DeleteClientDispatch);
            }
        });
    }

    return (
        <React.Fragment>
            <Box className={classes.root}>
                <Typography className={classes.title} variant="h5" gutterBottom>
                    Clients
                    <Box component={'span'}>List For All Clients And Clients Management Section.</Box>
                </Typography>
                <Button startIcon={<AddCircle />} variant="contained" color="primary" component={Link} to={'./clients/create'}>
                    Add New
                </Button>
            </Box>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.headCell} align="center" component="th" scope="row">Logo</TableCell>
                        <TableCell className={classes.headCell} align="left">Client Name</TableCell>
                        <TableCell className={classes.headCell} align="left">Description</TableCell>
                        <TableCell className={classes.headCell} align="left">Number Of Events</TableCell>
                        <TableCell className={classes.headCell} align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Data.data &&
                        <React.Fragment>
                            {(Data.data).map(Row => (
                                <TableRow className={classes.tableRow} key={Row.id}>
                                    <TableCell className={classes.tableCell} align="center">
                                        <img className={classes.thumbImage} src={`${Row.logo}`} alt=""/>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="left">
                                        <Box component={Link} to={`./clients/${Row.id}`}>
                                            {Row.name}
                                        </Box>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="left">{Row.description}</TableCell>
                                    <TableCell className={classes.tableCell} align="left">{Row.events_count}</TableCell>
                                    <TableCell className={classes.actionsButtons} align="center">
                                        <Button component={Link} to={`./clients/${Row.id}/edit`} variant="outlined">
                                            <Edit />
                                        </Button>
                                        <Button onClick={() => DeleteClient(`${Row.id}`)} variant="outlined">
                                            <Delete />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </React.Fragment>
                    }
                </TableBody>
            </Table>
        </React.Fragment>
    );
};

export default ClientsListLayout;
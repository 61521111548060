import React, {useContext, useEffect, useState} from 'react';

// Notifications
import {toast} from 'react-toastify';

// Sweet Alert
import Alert from 'sweetalert2';

// React Router
import { Link, useParams } from 'react-router-dom';

// Stores
import { DashboardStore } from "../../../../Sources/Stores/Dashboard/DashboardStore/DashboardStore";

// Actions
import EventsAction from "../../../../Sources/Actions/Dashboard/EventsAction/EventsAction";
import DeleteEventAction from "../../../../Sources/Actions/Dashboard/DeleteEventAction/DeleteEventAction";

// Material-UI Components
import {
    Box,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
} from '@material-ui/core';

// Material-UI Icons
import {Delete, Edit, AddCircle, FileCopy, Visibility} from '@material-ui/icons';

// Components
import EventUsersModal from "../../../../Components/Dashboard/EventUsersModal/EventUsersModal";

// Component Style
import useStyles from './EventsListLayout_Styles';

const EventsListLayout = () => {
    const classes = useStyles();
    const {ClientID} = useParams();
    const [UsersEvent, setUsersEvent] = useState({});
    const {EventsState: {Events: {Data}}, EventsDispatch, ClientInfoDispatch, DeleteEventDispatch, setEventUsersModal} = useContext(DashboardStore);

    // Get Events
    useEffect(() => {
        EventsAction(EventsDispatch);
    }, [EventsDispatch]);

    // Delete Event
    const DeleteEvent = (EventID) => {
        Alert.fire({
            title: 'Are you sure?',
            text : 'You will not be able to recover this event!',
            icon : 'warning',
            showCancelButton : true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText : 'No, keep it',
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteEventAction(EventID)(ClientID)(ClientInfoDispatch)(EventsDispatch)(DeleteEventDispatch);
            }
        });
    }

    // Edit Event Users
    const EventUsers = (EventHash) => {
        setEventUsersModal(true);
        setUsersEvent(EventHash);
    }

    // Date Handling
    const DateHandling = (FullDate) => {
        const DatabaseDate = new Date(FullDate);
        const Year = DatabaseDate.getFullYear();
        const Month = DatabaseDate.getMonth() + 1;
        const Day = DatabaseDate.getDate();
        return (`${Day}-${Month}-${Year}`);
    }

    return (
        <React.Fragment>
            <Box className={classes.root}>
                <Typography className={classes.title} variant="h5" gutterBottom>
                    Events
                    <Box component={'span'}>List For All Events And Events Management Section.</Box>
                </Typography>
                <Button startIcon={<AddCircle />} variant="contained" color="primary" component={Link} to={'./events/create'}>
                    Add New
                </Button>
            </Box>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.headCell} align="center" component="th" scope="row">Logo</TableCell>
                        <TableCell className={classes.headCell} align="left">Event Name</TableCell>
                        <TableCell className={classes.headCell} align="left">client</TableCell>
                        <TableCell className={classes.headCell} align="left">Start Date</TableCell>
                        <TableCell className={classes.headCell} align="left">Expire Date</TableCell>
                        <TableCell className={classes.headCell} align="left">certificate</TableCell>
                        <TableCell className={classes.headCell} align="left">Survey</TableCell>
                        <TableCell className={classes.headCell} align="left">Users</TableCell>
                        <TableCell className={classes.headCell} align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Data.data &&
                        <React.Fragment>
                            {(Data.data).map(Row => (
                                <TableRow className={classes.tableRow} key={Row.id}>
                                    <TableCell className={classes.tableCell} align="center">
                                        <img className={classes.thumbImage} src={`${Row.logo}`} alt=""/>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="left">{Row.name}</TableCell>
                                    <TableCell className={classes.tableCell} align="left">{Row.client?.name}</TableCell>
                                    <TableCell className={classes.tableCell} align="left">{DateHandling(Row.start_date)}</TableCell>
                                    <TableCell className={classes.tableCell} align="left">{DateHandling(Row.end_date)}</TableCell>
                                    <TableCell className={classes.tableCell} align="left">
                                        <img className={classes.thumbImage} src={`${Row.certificate.image}`} alt=""/>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="left">{Row.survey ? Row.survey.type.name : 'No Survey'}</TableCell>
                                    <TableCell className={classes.tableCell} align="left">
                                        <Button color="primary" onClick={() => EventUsers(`${Row.hash}`)}>
                                            {Row.users_count}
                                        </Button>
                                    </TableCell>
                                    <TableCell className={classes.actionsButtons} align="center">
                                        <Button target="_blank" component={Link} to={`../events/${Row.hash}`} variant="outlined">
                                            <Visibility />
                                        </Button>
                                        <Button onClick={() => {navigator.clipboard.writeText(`${window.location.hostname}/events/${Row.hash}`).then(() => { toast ('Event Link is Copied!', {autoClose: 3000, draggable: true, closeOnClick: true, pauseOnHover: true, progress: undefined, position: "top-right", hideProgressBar: false, });})}} variant="outlined">
                                            <FileCopy />
                                        </Button>
                                        <Button component={Link} to={`./events/${Row.id}/edit`} variant="outlined">
                                            <Edit />
                                        </Button>
                                        <Button onClick={() => DeleteEvent(`${Row.id}`)} variant="outlined">
                                            <Delete />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </React.Fragment>
                    }
                </TableBody>
            </Table>
            {UsersEvent && <EventUsersModal EventHash={UsersEvent} />}
        </React.Fragment>
    );
};

export default EventsListLayout;
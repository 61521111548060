import React, {createContext, useReducer, useState} from 'react';

// Reducers
import EventReducers from '../../../Reducers/Views/EventReducers';
import EventViewsReducers from '../../../Reducers/Views/EventViewsReducers';
import CertificateViewsReducers from '../../../Reducers/Views/CertificateViewsReducers';
import CertificateReducers from '../../../Reducers/Views/CertificateReducers';
import UserViewsReducers from '../../../Reducers/Views/UserViewsReducers';
import SurveyReducers from '../../../Reducers/Views/SurveyReducers';
import SkipSurveyReducers from '../../../Reducers/Views/SkipSurveyReducers';

// Initial
import EventInit from "../../../Init/Views/EventInit";
import EventViewsInit from "../../../Init/Views/EventViewsInit";
import CertificateViewsInit from "../../../Init/Views/CertificateViewsInit";
import CertificateInit from "../../../Init/Views/CertificateInit";
import UserViewsInit from "../../../Init/Views/UserViewsInit";
import SurveyInit from "../../../Init/Views/SurveyInit";
import SkipSurveyInit from "../../../Init/Views/SkipSurveyInit";

export const ViewsStore = createContext({});

const ViewsProvider = ({children}) => {
    const [Cities, setCities] = useState('');
    const [Countries, setCountries] = useState('');
    const [EventState, EventDispatch] = useReducer(EventReducers, EventInit);
    const [EventViewsState, EventViewsDispatch] = useReducer(EventViewsReducers, EventViewsInit);
    const [CertificateViewsState, CertificateViewsDispatch] = useReducer(CertificateViewsReducers, CertificateViewsInit);
    const [CertificateState, CertificateDispatch] = useReducer(CertificateReducers, CertificateInit);
    const [UserViewsState, UserViewsDispatch] = useReducer(UserViewsReducers, UserViewsInit);
    const [SurveyState, SurveyDispatch] = useReducer(SurveyReducers, SurveyInit);
    const [SkipSurveyState, SkipSurveyDispatch] = useReducer(SkipSurveyReducers, SkipSurveyInit);

    return (
        <ViewsStore.Provider value={{
            Cities, setCities,
            Countries, setCountries,
            EventState, EventDispatch,
            EventViewsState, EventViewsDispatch,
            UserViewsState, UserViewsDispatch,
            SurveyState, SurveyDispatch,
            CertificateViewsState, CertificateViewsDispatch,
            CertificateState, CertificateDispatch,
            SkipSurveyState, SkipSurveyDispatch,
        }}>
            {children}
        </ViewsStore.Provider>
    )
}

export default ViewsProvider;
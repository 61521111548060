// Actions Types
import {CLIENTS_LOADING, CLIENTS_SUCCESS, CLIENTS_ERRORS} from "../../Types/Dashboard/ClientsType/ClientsType";

const ClientsReducers = (state, {payload, type}) => {
    switch (type) {
        case CLIENTS_LOADING :
            return {
                ...state,
                Clients: {
                    ...state.Clients,
                    Loading: true,
                    Error: false
                }
            }
        case CLIENTS_SUCCESS :
            return {
                ...state,
                Clients: {
                    ...state.Clients,
                    Loading: false,
                    Data: payload,
                }
            }
        case CLIENTS_ERRORS :
            return {
                ...state,
                Clients: {
                    ...state.Clients,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default ClientsReducers;
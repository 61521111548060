// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Notifications
import {toast} from 'react-toastify';

// Actions Types
import {NEW_CLIENT_ERRORS, NEW_CLIENT_LOADING, NEW_CLIENT_SUCCESS} from "../../../Types/Dashboard/NewClientType/NewClientType";

const NewClientAction = (History) => (FormFormat) => (NewClientDispatch) => {

    NewClientDispatch({
        type: NEW_CLIENT_LOADING,
    })

    AxiosInstance()
        .post(`/dashboard/clients`, FormFormat)
        .then(Response => {
            NewClientDispatch({
                type: NEW_CLIENT_SUCCESS,
                payload: Response.data,
            });
            History.push('/dashboard/clients');
            toast('New Client Add Is Success!', {
                autoClose: 3000,
                draggable: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                position: "top-right",
                hideProgressBar: false,
            });
        })
        .catch(Error => (
            NewClientDispatch({
                type: NEW_CLIENT_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
        ));

}

export default NewClientAction;
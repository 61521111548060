import React from "react";

// React Router
import { Redirect } from "react-router-dom";

// Authenticated Check
import AuthDashboardMiddle from '../Middlewares/Dashboard/AuthDashboardMiddle';

// Routes Permissions
import RouterRender from './RouterRender';

// Containers
// Dashboard
import AuthDashboardContainer from "../Containers/Dashboard/AuthDashboardContainer/AuthDashboardContainer";
import DashboardContainer from "../Containers/Dashboard/DashboardContainer/DashboardContainer";

// Front Views
import FrontViewContainer from "../Containers/Views/FrontViewContainer/FrontViewContainer";

// Routes List
const RoutesMaster = [

    // Master Route
    {
        path: '/',
        component: (props) => {
            return (
                <RouterRender {...props} />
            )
        },

        // Public Routes
        PermissionsRoute: [
            {
                path: '/',
                exact: true,
                title: 'Error 404',
                authRequired: false,
                component: () => {
                    return (
                        <Redirect to={'/auth/login'} />
                    )
                }
            },
            {
                path: '/events/:EventID',
                component: (props) => {
                    return (
                        <RouterRender {...props} />
                    )
                },
                
                // Views Routes
                PermissionsRoute: [
                    {
                        path: '/events/:EventID',
                        exact: true,
                        title: 'Login',
                        authRequired: false,
                        component: FrontViewContainer
                    },
                    {
                        path: '/events/:EventID/profile',
                        exact: true,
                        title: 'Profile',
                        authRequired: false,
                        component: FrontViewContainer
                    },
                    {
                        path: '/events/:EventID/survey',
                        exact: true,
                        title: 'Survey',
                        authRequired: false,
                        component: FrontViewContainer
                    },
                    {
                        path: '/events/:EventID/certificate',
                        exact: true,
                        title: 'Certificate',
                        authRequired: false,
                        component: FrontViewContainer
                    }
                ]
            },
            {
                path: '/dashboard',
                component: (props) => {
                    if (AuthDashboardMiddle()) {
                        return (
                            <RouterRender {...props} />
                        )
                    } else {
                        return (
                            <Redirect to={'/auth/login'} />
                        )
                    }
                },

                // Dashboard Routes
                PermissionsRoute: [
                    {
                        path: '/dashboard',
                        exact: true,
                        title: 'Dashboard',
                        authRequired: false,
                        component: DashboardContainer,
                    },
                    {
                        path: '/dashboard/clients',
                        exact: true,
                        title: 'Dashboard - Clients',
                        authRequired: false,
                        component: DashboardContainer
                    },
                    {
                        path: '/dashboard/clients/create',
                        exact: true,
                        title: 'Dashboard - New Client',
                        authRequired: false,
                        component: DashboardContainer
                    },
                    {
                        path: '/dashboard/clients/:ClientID',
                        exact: true,
                        title: 'Dashboard - Client Information',
                        authRequired: false,
                        component: DashboardContainer
                    },
                    {
                        path: '/dashboard/clients/:ClientID/edit',
                        exact: true,
                        title: 'Dashboard - Edit Client',
                        authRequired: false,
                        component: DashboardContainer
                    },
                    {
                        path: '/dashboard/events',
                        exact: true,
                        title: 'Dashboard - Events',
                        authRequired: false,
                        component: DashboardContainer
                    },
                    {
                        path: '/dashboard/events/create',
                        exact: true,
                        title: 'Dashboard - New Event',
                        authRequired: false,
                        component: DashboardContainer
                    },
                    {
                        path: '/dashboard/events/:EventID',
                        exact: true,
                        title: 'Dashboard - Event Information',
                        authRequired: false,
                        component: DashboardContainer
                    },
                    {
                        path: '/dashboard/events/:EventID/edit',
                        exact: true,
                        title: 'Dashboard - Edit Event',
                        authRequired: false,
                        component: DashboardContainer
                    },
                ]
            },
            {
                path: '/auth',
                component: (props) => {
                    if (!AuthDashboardMiddle()) {
                        return (
                            <RouterRender {...props} />
                        )
                    } else {
                        return (
                            <Redirect to={'/dashboard'} />
                        )
                    }
                },

                // Dashboard Auth Routes
                PermissionsRoute: [
                    {
                        path: '/auth/login',
                        exact: true,
                        title: 'Dashboard - Login',
                        authRequired: false,
                        component: AuthDashboardContainer
                    },
                    {
                        path: '/auth/forgot-password',
                        exact: true,
                        title: 'Dashboard - Forgot Password',
                        authRequired: false,
                        component: AuthDashboardContainer
                    },
                    {
                        path: '/auth/reset-password',
                        exact: true,
                        title: 'Dashboard - Reset Password',
                        authRequired: false,
                        component: AuthDashboardContainer
                    }
                ]
            }
        ]
    },
]

export default RoutesMaster;
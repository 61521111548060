// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Notifications
import {toast} from 'react-toastify';

// Actions Types
import {EDIT_EVENT_ERRORS, EDIT_EVENT_LOADING, EDIT_EVENT_SUCCESS} from "../../../Types/Dashboard/EditEventType/EditEventType";

const EditEventAction = (History) => (EventID) => (FormFormat) => (EditEventDispatch) => {

    FormFormat.append('_method', 'put');

    EditEventDispatch({
        type: EDIT_EVENT_LOADING,
    })

    AxiosInstance()
        .post(`/dashboard/events/${EventID}`, FormFormat)
        .then(Response => {
            EditEventDispatch({
                type: EDIT_EVENT_SUCCESS,
                payload: Response.data,
            });
            History.push('/dashboard/events');
            if (Response.data.survey_updated === false) {
                toast('Event Data Updated Is Success Except Survey Because There Some Users Already Took!', {
                    autoClose: 3000,
                    draggable: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    position: "top-right",
                    hideProgressBar: false,
                });
            } else {
                toast('Event Data Updated Is Success!', {
                    autoClose: 3000,
                    draggable: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    position: "top-right",
                    hideProgressBar: false,
                });
            }
        })
        .catch(Error => {
            EditEventDispatch({
                type: EDIT_EVENT_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
            if (Error.response.status === 403) {
                toast("You can't edit this event because there survey or certificate is taken!", {
                    autoClose: 3000,
                    draggable: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    position: "top-right",
                    hideProgressBar: false,
                });
            } else if (Error.response.status === 422) {
                toast(`${Error.response.data.message}`, {
                    autoClose: 3000,
                    draggable: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    position: "top-right",
                    hideProgressBar: false,
                });
            }
        });

}

export default EditEventAction;
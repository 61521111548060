import React from 'react';

// Store Providers
import ViewsProvider from './Views/ViewsStore/ViewsStore';
import DashboardProvider from './Dashboard/DashboardStore/DashboardStore';

const MasterStore = ({children}) => {
    return (
        <React.Fragment>
            <ViewsProvider>
                <DashboardProvider>
                    {children}
                </DashboardProvider>
            </ViewsProvider>
        </React.Fragment>
    );
};

export default MasterStore;
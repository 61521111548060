import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flex:'1 1 auto',
    },
    formCont: {
        order:'1',
        display:'flex',
        padding: '15px',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection:'column',
        justifyContent:'center',
        backgroundColor: theme.palette.primary.contrastText,
        [theme.breakpoints.down('sm')]: {
            order:'2',
            width:'100%',
            padding: '25px',
        },
    },
    formTitle: {
        padding: theme.spacing(2),
        '& h3': {
            margin: '0 0 8px',
            textTransform:'uppercase',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(4),
            },
        },
        '& h6': {
            maxWidth: '500px',
            lineHeight: theme.spacing(0.18),
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(1.7),
            },
        },
    },
    form: {
        width:'100%',
        display:'flex',
        margin:'0 auto',
        maxWidth: '550px',
        flexDirection:'column',
        justifyContent:'center',
    },
    input: {
        marginBottom: theme.spacing(1),
        '& label': {
            transform: 'translate(30px, 17px) scale(1)',
            '&.MuiInputLabel-shrink': {
                transform: 'translate(30px, -6px) scale(0.75)',
            },
        },
        '& input': {
            padding: '15px 30px',
        },
        '& fieldset': {
            borderRadius: '50px',
            padding: '0 23px',
        },
        '& .MuiFormHelperText-contained': {
            color: theme.palette.error.main
        },
    },
    submit: {
        fontWeight: '400',
        padding: '8px 65px',
        width: 'max-content',
        borderRadius: '50px',
        margin: '16px auto 0',
        fontSize: theme.spacing(2),
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
        },
    },
    welcomeCont: {
        order:'2',
        display:'flex',
        padding: '15px',
        alignItems:'center',
        flexDirection: 'column',
        justifyContent:'center',
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            order:'1',
            width:'100%',
            padding: '25px',
        },
        '& h2': {
            letterSpacing: theme.spacing(1),
            textTransform: 'uppercase',
            fontSize: theme.spacing(6),
            margin: 0,
            [theme.breakpoints.down('sm')]: {
                letterSpacing: theme.spacing(0.6),
                fontSize: theme.spacing(4),
            },
        },
        '& h3': {
            fontSize: theme.spacing(5),
            textTransform: 'uppercase',
            margin: 0,
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(4.2),
            },
        },
    },
}));

export default useStyles;
// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

const EventsListAction = (setEventsList) => {
    AxiosInstance()
        .get('/dashboard/events', { params: { select: 'id,name' } })
        .then(Response => (
            setEventsList(Response.data.data)
        ))
        .catch(Error);
}

export default EventsListAction;
import React from 'react';

// Material-UI Components
import { Box, Typography } from '@material-ui/core';

// Component Style
import useStyles from './EventNotExistContainers_Style';

const EventNotExistContainers = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Box className={classes.root}>
                <Typography variant="h4" gutterBottom>
                    Event Not Exist
                </Typography>
            </Box>
        </React.Fragment>
    );
};

export default EventNotExistContainers;
import React, {useContext, useRef, useState} from 'react';

// React Router
import { useHistory } from 'react-router-dom';

// Store Provider
import { ViewsStore } from "../../../Sources/Stores/Views/ViewsStore/ViewsStore";

// Material-UI Components
import {Grid, Typography, Box, Button, CircularProgress} from "@material-ui/core";

// Components
import QuestionLayout from "./Survey/Question/QuestionLayout";

// Actions
import SurveyAction from "../../../Sources/Actions/Views/SurveyAction/SurveyAction";
import SkipSurveyAction from "../../../Sources/Actions/Views/SkipSurveyAction/SkipSurveyAction";

// Component Style
import useStyles from "./SurveyLayout_Styles";

const SurveyLayout = ({event, survey}) => {
    const classes = useStyles();
    const SurveyForm = useRef();
    const History = useHistory();
    const [FormValues, setFormValues] = useState({});
    const {SurveyState: {Survey: {Loading}}, SurveyDispatch, SkipSurveyState: {SkipSurvey: {Loading: SkipLoading}}, SkipSurveyDispatch} = useContext(ViewsStore);

    const SurveyAnswers = () => {
        const SurveyForms = new FormData(SurveyForm.current);
        SurveyAction(History)(survey.id)(SurveyForms)(SurveyDispatch);
    }

    const SkipSurvey = () => {
        SkipSurveyAction(History)(survey.id)(SkipSurveyDispatch);
    }

    const FormValid = ((survey.questions.length) !== (Object.keys(FormValues).length));

    return (
        <React.Fragment>
            <Grid container spacing={3} className={classes.root}>
                <Box className={classes.title}>
                    <Typography variant="h3" gutterBottom style={{color: `${event.title_color}`}}>
                        Survey
                    </Typography>
                </Box>
                <form ref={SurveyForm} className={classes.survey}>
                    {survey?.questions.map((Question, Index) => {
                        return <QuestionLayout key={Index} Style={event} Question={Question} Number={Index} FormValues={FormValues} setFormValues={setFormValues} />
                    })}
                </form>
                <Grid>
                    {(survey.type.id === 1) &&
                        <Button onClick={SkipSurvey} disabled={SkipLoading} variant="outlined" disableElevation className={classes.submit} style={{color: `${event.title_color}`, marginRight: '7px'}}>
                            {(SkipLoading)? <CircularProgress /> : <React.Fragment>Skip</React.Fragment>}
                        </Button>
                    }
                    <Button onClick={SurveyAnswers} id="Submit" disabled={FormValid || Loading} variant="contained" disableElevation className={classes.submit} style={{backgroundColor: `${event.main_color}`}}>
                        {(Loading)? <CircularProgress /> : <React.Fragment>Submit</React.Fragment>}
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default SurveyLayout;
// Actions Types
import {DELETE_EVENT_LOADING, DELETE_EVENT_SUCCESS, DELETE_EVENT_ERRORS} from "../../Types/Dashboard/DeleteEventType/DeleteEventType";

const DeleteEventReducers = (state, {payload, type}) => {
    switch (type) {
        case DELETE_EVENT_LOADING :
            return {
                ...state,
                DeleteEvent: {
                    ...state.DeleteEvent,
                    Loading: true,
                    Error: false
                }
            }
        case DELETE_EVENT_SUCCESS :
            return {
                ...state,
                DeleteEvent: {
                    ...state.DeleteEvent,
                    Loading: false,
                    Data: payload,
                }
            }
        case DELETE_EVENT_ERRORS :
            return {
                ...state,
                DeleteEvent: {
                    ...state.DeleteEvent,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default DeleteEventReducers;
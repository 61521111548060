// API Instance
import AxiosInstance from "../../../../Database/Views/AxiosInstance";

// Actions Types
import {USER_LOADING, USER_SUCCESS, USER_ERRORS} from "../../../Types/Views/UserViewsType/UserViewsType";
import {toast} from "react-toastify";

const LoginViewsAction = (EventID) => ({email}) => (UserViewsDispatch) => {

    UserViewsDispatch({
        type: USER_LOADING,
    })

    AxiosInstance()
        .post(`/login/${EventID}`, {email})
        .then(Response => {
            localStorage[EventID] = Response.data.token.access_token;
            UserViewsDispatch({
                type: USER_SUCCESS,
                payload: Response.data.user,
            })
        })
        .catch(Error => {
            UserViewsDispatch({
                type: USER_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
            if (Error.response.status === 403) {
                toast("You can't access to this event because it out of date range!", {
                    autoClose: 3000,
                    draggable: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    position: "top-right",
                    hideProgressBar: false,
                });
            }
        });

}

export default LoginViewsAction;
import React from 'react';

// Operations
import LoginFunctions from "../../../Functions/Views/LoginFunctions/LoginFunctions";

// Material-UI Components
import { Grid, Box, Typography, TextField, Button, CircularProgress } from '@material-ui/core';

// Component Style
import useStyles from './LoginLayout_Styles';

const LoginLayout = (props) => {
    const Event = props.event;
    const classes = useStyles();
    const {LoginViews, FormData, FormValid, FormSubmit, Loading, Error, FormErrors} = LoginFunctions();

    window.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            document.getElementById("Submit").click();
        }
    });

    return (
        <React.Fragment>
            <Grid container spacing={0} className={classes.root}>
                <Grid item md={6} className={classes.welcomeCont} style={{backgroundColor: `${Event.main_color}`}}>
                    <Typography variant="h3" gutterBottom style={{color: `${Event.font_color}`}}>
                        Welcome To
                    </Typography>
                    <Typography variant="h2" gutterBottom style={{color: `${Event.font_color}`}}>
                        {Event.name}
                    </Typography>
                </Grid>

                <Grid item md={6} className={classes.formCont}>
                    <Box className={classes.formTitle}>
                        <Typography variant="h3" gutterBottom style={{color: `${Event.title_color}`}}>
                            Welcome
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Please enter your email address, on which you received the link to this event.
                        </Typography>
                    </Box>
                    <form noValidate autoComplete="off" className={classes.form} >
                        <TextField className={classes.input} value={LoginViews.email || ''} onChange={FormData} type="email" name="email" label="Email" variant="outlined" error={!!FormErrors.email} helperText={(Error && Error.errors?.email) || FormErrors.email} />
                        <Button onClick={FormSubmit} id="Submit" disabled={FormValid || Loading || !!FormErrors.email} variant="contained" disableElevation className={classes.submit} style={{backgroundColor: `${Event.main_color}`}}>
                            {(Loading)? <CircularProgress /> : <React.Fragment>Login</React.Fragment>}
                        </Button>
                    </form>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default LoginLayout;
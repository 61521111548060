import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        maxWidth: '850px',
        width: '95%',
    },
    title: {
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(1.5),
        borderBottom: '1px solid #d6d6d6',
        '& button': {
            padding: theme.spacing(0, 2),
        }
    },
    table: {
        width: '100%',
        height: '300px',
        margin: theme.spacing(3, 0),
        '& .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-window': {
            top: '44px !important'
        },
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            minHeight: '45px !important',
            maxHeight: '35px !important',
            lineHeight: '35px !important',
        },
        '& .MuiDataGrid-columnHeaderDraggableContainer': {
            '& span': {
                display: 'none',
            },
        },
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));

export default useStyles;
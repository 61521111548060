import {useContext, useState} from 'react';

// React Router
import { useHistory, useLocation } from 'react-router-dom';

// Store Provider
import { DashboardStore } from "../../../Sources/Stores/Dashboard/DashboardStore/DashboardStore";

// Actions
import ResetPasswordDashboardAction from "../../../Sources/Actions/Dashboard/ResetPasswordDashboardAction/ResetPasswordDashboardAction";
import FormValidation from "../../Validation/FormsValidations";

const ResetPasswordFunctions = () => {
    const History = useHistory();
    const { search } = useLocation();
    const SearchParams = new URLSearchParams(search);
    const Token = SearchParams.get('token');
    const Email = SearchParams.get('email');
    const [FormErrors, setFormErrors] = useState({});
    const [ResetPasswordDashboard, setResetPasswordDashboard] = useState({});
    const {ResetPasswordDashboardState: {ResetPasswordDashboard: {Loading, Error, Data}}, ResetPasswordDashboardDispatch} = useContext(DashboardStore);

    // Fields Validation
    const FieldsValidation = {
        password: {
            error: "",
            minLength: 6,
        },
        password_confirmation: {
            error: "",
            minLength: 6,
        }
    };

    // Get Form Data
    const FormData = (event) => {
        const { target: { value, name } } = event;
        setResetPasswordDashboard({...ResetPasswordDashboard, [name]: value});

        // Set Errors
        const error = FormValidation(name, value, FieldsValidation) || '';
        setFormErrors({...FormErrors, [name]: error});
    };

    // Form Validation
    const FormValid =
        !ResetPasswordDashboard.password?.length ||
        !ResetPasswordDashboard.password_confirmation?.length

    // Form Submit
    const FormSubmit = () => {
        ResetPasswordDashboardAction(History)(Token, Email)(ResetPasswordDashboard)(ResetPasswordDashboardDispatch);
    }

    return {ResetPasswordDashboard, FormData, FormValid, FormSubmit, Loading, Error, Data, FormErrors};
}

export default ResetPasswordFunctions;
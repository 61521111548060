// Actions Types
import {EVENT_LOADING, EVENT_SUCCESS, EVENT_ERRORS} from "../../Types/Views/EventType/EventType";

const EventReducers = (state, {payload, type}) => {
    switch (type) {
        case EVENT_LOADING :
            return {
                ...state,
                Event: {
                    ...state.Event,
                    Loading: true,
                    Error: false
                }
            }
        case EVENT_SUCCESS :
            return {
                ...state,
                Event: {
                    ...state.Event,
                    Loading: false,
                    Data: payload,
                }
            }
        case EVENT_ERRORS :
            return {
                ...state,
                Event: {
                    ...state.Event,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default EventReducers;
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 96px)',
    },
    input: {
        margin: theme.spacing(2, 0, 10),
        backgroundColor: '#e8e8e8',
    },
    singleCard: {
        '& .MuiPaper-root': {
            backgroundColor: '#dedede',
        }
    },
    cardContent: {
        width: '100%',
        '& .MuiCardActions-root': {
            padding: 0,
            justifyContent: 'center',
            backgroundColor: '#d2d2d2',
        },
        '& .MuiButton-root': {
            width: '100%',
            height: '46px',
            color: theme.palette.success.dark,
        },
        '& .MuiButton-root.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)',
        },
    },
    cardMedia: {
        height: 200,
        backgroundColor: '#a1aab5',
    }
}));

export default useStyles;
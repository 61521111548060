import React from 'react';

// Operations
import ResetPasswordFunctions from "../../../../Functions/Dashboard/ResetPasswordFunctions/ResetPasswordFunctions";

// Material-UI Components
import { Grid, Box, Typography, TextField, Button, CircularProgress } from '@material-ui/core';

// Component Style
import useStyles from './ResetPasswordLayout_Styles';

const ResetPasswordLayout = () => {
    const classes = useStyles();
    const {ResetPasswordDashboard, FormData, FormValid, FormSubmit, Loading, Error, FormErrors} = ResetPasswordFunctions();

    window.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            document.getElementById("Submit").click();
        }
    });

    const FormErrorsCheck = !!FormErrors.password ||
                            !!FormErrors.password_confirmation;

    return (
        <React.Fragment>
            <Grid container spacing={0} className={classes.root}>
                <Grid item md={6} className={classes.welcomeCont}>
                    <Typography variant="h3" gutterBottom>
                        Welcome To
                    </Typography>
                    <Typography variant="h2" gutterBottom>
                        CME System
                    </Typography>
                </Grid>

                <Grid item md={6} className={classes.formCont}>
                    <Box className={classes.formTitle}>
                        <Typography variant="h3" gutterBottom>
                            Reset Password
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Please enter your new email
                        </Typography>
                    </Box>
                    <form noValidate autoComplete="off" className={classes.form} >
                        <TextField className={classes.input} value={ResetPasswordDashboard.password || ''} onChange={FormData} type="password" name="password" label="New Password" variant="outlined" error={!!FormErrors.password} helperText={((Error && Error.errors) && Error.errors.password) || FormErrors.password} />
                        <TextField className={classes.input} value={ResetPasswordDashboard.password_confirmation || ''} onChange={FormData} type="password" name="password_confirmation" label="Password Confirmation" variant="outlined" error={!!FormErrors.password_confirmation} helperText={((Error && Error.errors) && Error.errors.password_confirmation) || FormErrors.password_confirmation} />
                        {(Error && Error.errors.email) &&
                            <Typography variant="caption" display="block" color={"error"} gutterBottom>
                                {Error.errors.email}
                            </Typography>
                        }
                        <Button onClick={FormSubmit} id="Submit" disabled={FormValid || Loading || FormErrorsCheck} variant="contained" disableElevation className={classes.submit}>
                            {(Loading)? <CircularProgress /> : <React.Fragment>Save</React.Fragment>}
                        </Button>
                    </form>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ResetPasswordLayout;
import React from 'react';

// React Router
import {Link as LinkRouter} from 'react-router-dom';

// Operations
import ForgotPasswordFunctions from "../../../../Functions/Dashboard/ForgotPasswordFunctions/ForgotPasswordFunctions";

// Material-UI Components
import { Grid, Box, Typography, TextField, Button, CircularProgress, Link } from '@material-ui/core';

// Component Style
import useStyles from './ForgotPasswordLayout_Styles';

const ForgotPasswordLayout = () => {
    const classes = useStyles();
    const {ForgotPasswordDashboard, FormData, FormValid, FormSubmit, Loading, Error, FormErrors} = ForgotPasswordFunctions();

    window.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            document.getElementById("Submit").click();
        }
    });

    return (
        <React.Fragment>
            <Grid container spacing={0} className={classes.root}>
                <Grid item md={6} className={classes.welcomeCont}>
                    <Typography variant="h3" gutterBottom>
                        Welcome To
                    </Typography>
                    <Typography variant="h2" gutterBottom>
                        CME System
                    </Typography>
                </Grid>

                <Grid item md={6} className={classes.formCont}>
                    <Box className={classes.formTitle}>
                        <Typography variant="h3" gutterBottom>
                            Forgot Password
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Please enter your email
                        </Typography>
                    </Box>
                    <form noValidate autoComplete="off" className={classes.form} >
                        <TextField className={classes.input} value={ForgotPasswordDashboard.email || ''} onChange={FormData} type="email" name="email" label="Email" variant="outlined" error={!!FormErrors.email} helperText={((Error && Error.errors) && Error.errors.email) || FormErrors.email} />
                        <Typography gutterBottom>
                            <Link component={LinkRouter} to={'./login'}>
                                Login
                            </Link>
                        </Typography>
                        <Button onClick={FormSubmit} id="Submit" disabled={FormValid || Loading || !!FormErrors.email} variant="contained" disableElevation className={classes.submit}>
                            {(Loading)? <CircularProgress /> : <React.Fragment>Send</React.Fragment>}
                        </Button>
                    </form>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ForgotPasswordLayout;
// Actions Types
import {SURVEY_LOADING, SURVEY_SUCCESS, SURVEY_ERRORS} from "../../Types/Views/SurveyType/SurveyType";

const SurveyReducers = (state, {payload, type}) => {
    switch (type) {
        case SURVEY_LOADING :
            return {
                ...state,
                Survey: {
                    ...state.Survey,
                    Loading: true,
                    Error: false
                }
            }
        case SURVEY_SUCCESS :
            return {
                ...state,
                Survey: {
                    ...state.Survey,
                    Loading: false,
                    Data: payload,
                }
            }
        case SURVEY_ERRORS :
            return {
                ...state,
                Survey: {
                    ...state.Survey,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default SurveyReducers;
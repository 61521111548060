// API Instance
import AxiosInstance from "../../../../Database/Views/AxiosInstance";

// Actions Types
import {CERTIFICATE_VIEWS_ERRORS, CERTIFICATE_VIEWS_LOADING, CERTIFICATE_VIEWS_SUCCESS} from "../../../Types/Views/CertificateViewsType/CertificateViewsType";

const CertificateViewsAction = (EventID) => (CertificateViewsDispatch) => {

    CertificateViewsDispatch({
        type: CERTIFICATE_VIEWS_LOADING,
    })

    AxiosInstance()
        .get(`/events/${EventID}/certificate`)
        .then(Response => {
            CertificateViewsDispatch({
                type: CERTIFICATE_VIEWS_SUCCESS,
                payload: Response.data.data,
            });
        })
        .catch(Error => (
            CertificateViewsDispatch({
                type: CERTIFICATE_VIEWS_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
        ));

}

export default CertificateViewsAction;
import React, {createContext, useState, useReducer} from 'react';

// Reducers
import LoginDashboardReducers from '../../../Reducers/Dashboard/LoginDashboardReducers';
import LogoutDashboardReducers from '../../../Reducers/Dashboard/LogoutDashboardReducers';
import ForgotPasswordDashboardReducers from '../../../Reducers/Dashboard/ForgotPasswordDashboardReducers';
import ResetPasswordDashboardReducers from '../../../Reducers/Dashboard/ResetPasswordDashboardReducers';
import ClientsReducers from '../../../Reducers/Dashboard/ClientsReducers';
import NewClientReducers from '../../../Reducers/Dashboard/NewClientReducers';
import ShowClientReducers from '../../../Reducers/Dashboard/ShowClientReducers';
import EditClientReducers from '../../../Reducers/Dashboard/EditClientReducers';
import DeleteClientReducers from '../../../Reducers/Dashboard/DeleteClientReducers';
import ClientInfoReducers from '../../../Reducers/Dashboard/ClientInfoReducers';
import EventsReducers from '../../../Reducers/Dashboard/EventsReducers';
import DeleteEventReducers from '../../../Reducers/Dashboard/DeleteEventReducers';
import NewEventReducers from '../../../Reducers/Dashboard/NewEventReducers';
import ShowEventReducers from '../../../Reducers/Dashboard/ShowEventReducers';
import EditEventReducers from '../../../Reducers/Dashboard/EditEventReducers';
import EventUsersReducers from '../../../Reducers/Dashboard/EventUsersReducers';
import AddEventUsersReducers from '../../../Reducers/Dashboard/AddEventUsersReducers';
import DeleteEventUsersReducers from '../../../Reducers/Dashboard/DeleteEventUsersReducers';

// Initial
import LoginDashboardInit from "../../../Init/Dashboard/LoginDashboardInit";
import LogoutDashboardInit from "../../../Init/Dashboard/LogoutDashboardInit";
import ForgotPasswordDashboardInit from "../../../Init/Dashboard/ForgotPasswordDashboardInit";
import ResetPasswordDashboardInit from "../../../Init/Dashboard/ResetPasswordDashboardInit";
import ClientsInit from "../../../Init/Dashboard/ClientsInit";
import NewClientInit from "../../../Init/Dashboard/NewClientInit";
import ShowClientInit from "../../../Init/Dashboard/ShowClientInit";
import EditClientInit from "../../../Init/Dashboard/EditClientInit";
import DeleteClientInit from "../../../Init/Dashboard/DeleteClientInit";
import ClientInfoInit from "../../../Init/Dashboard/ClientInfoInit";
import EventsInit from "../../../Init/Dashboard/EventsInit";
import DeleteEventInit from "../../../Init/Dashboard/DeleteEventInit";
import NewEventInit from "../../../Init/Dashboard/NewEventInit";
import ShowEventInit from "../../../Init/Dashboard/ShowEventInit";
import EditEventInit from "../../../Init/Dashboard/EditEventInit";
import EventUsersInit from "../../../Init/Dashboard/EventUsersInit";
import AddEventUsersInit from "../../../Init/Dashboard/AddEventUsersInit";
import DeleteEventUsersInit from "../../../Init/Dashboard/DeleteEventUsersInit";

export const DashboardStore = createContext({});

const ViewsProvider = ({children}) => {
    const [DashSideMenu, setDashSideMenu] = useState(true);
    const [EventUsersModal, setEventUsersModal] = useState(false);
    const [LoginDashboardState, LoginDashboardDispatch] = useReducer(LoginDashboardReducers, LoginDashboardInit);
    const [LogoutDashboardState, LogoutDashboardDispatch] = useReducer(LogoutDashboardReducers, LogoutDashboardInit);
    const [ForgotPasswordDashboardState, ForgotPasswordDashboardDispatch] = useReducer(ForgotPasswordDashboardReducers, ForgotPasswordDashboardInit);
    const [ResetPasswordDashboardState, ResetPasswordDashboardDispatch] = useReducer(ResetPasswordDashboardReducers, ResetPasswordDashboardInit);
    const [ClientsState, ClientsDispatch] = useReducer(ClientsReducers, ClientsInit);
    const [NewClientState, NewClientDispatch] = useReducer(NewClientReducers, NewClientInit);
    const [ShowClientState, ShowClientDispatch] = useReducer(ShowClientReducers, ShowClientInit);
    const [EditClientState, EditClientDispatch] = useReducer(EditClientReducers, EditClientInit);
    const [DeleteClientState, DeleteClientDispatch] = useReducer(DeleteClientReducers, DeleteClientInit);
    const [ClientInfoState, ClientInfoDispatch] = useReducer(ClientInfoReducers, ClientInfoInit);
    const [EventsState, EventsDispatch] = useReducer(EventsReducers, EventsInit);
    const [DeleteEventState, DeleteEventDispatch] = useReducer(DeleteEventReducers, DeleteEventInit);
    const [NewEventState, NewEventDispatch] = useReducer(NewEventReducers, NewEventInit);
    const [ShowEventState, ShowEventDispatch] = useReducer(ShowEventReducers, ShowEventInit);
    const [EditEventState, EditEventDispatch] = useReducer(EditEventReducers, EditEventInit);
    const [EventUsersState, EventUsersDispatch] = useReducer(EventUsersReducers, EventUsersInit);
    const [AddEventUsersState, AddEventUsersDispatch] = useReducer(AddEventUsersReducers, AddEventUsersInit);
    const [DeleteEventUsersState, DeleteEventUsersDispatch] = useReducer(DeleteEventUsersReducers, DeleteEventUsersInit);

    return (
        <DashboardStore.Provider value={{
            DashSideMenu, setDashSideMenu,
            EventUsersModal, setEventUsersModal,
            LoginDashboardState, LoginDashboardDispatch,
            LogoutDashboardState, LogoutDashboardDispatch,
            ForgotPasswordDashboardState, ForgotPasswordDashboardDispatch,
            ResetPasswordDashboardState, ResetPasswordDashboardDispatch,
            ClientsState, ClientsDispatch,
            NewClientState, NewClientDispatch,
            ShowClientState, ShowClientDispatch,
            EditClientState, EditClientDispatch,
            DeleteClientState, DeleteClientDispatch,
            ClientInfoState, ClientInfoDispatch,
            EventsState, EventsDispatch,
            DeleteEventState, DeleteEventDispatch,
            NewEventState, NewEventDispatch,
            ShowEventState, ShowEventDispatch,
            EditEventState, EditEventDispatch,
            EventUsersState, EventUsersDispatch,
            AddEventUsersState, AddEventUsersDispatch,
            DeleteEventUsersState, DeleteEventUsersDispatch,
        }}>
            {children}
        </DashboardStore.Provider>
    )
}

export default ViewsProvider;
// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Actions
import EventsAction from "../EventsAction/EventsAction";

// Notifications
import {toast} from 'react-toastify';

// Actions Types
import {DELETE_EVENT_USERS_LOADING, DELETE_EVENT_USERS_SUCCESS, DELETE_EVENT_USERS_ERRORS} from "../../../Types/Dashboard/DeleteEventUsersType/DeleteEventUsersType";

const DeleteEventUsersAction = (EventHash) => (GridData) => (setEventUsersModal) => (EventsDispatch) => (setGridData) => (setAttendantsEmails) => (DeleteEventUsersDispatch) => {

    DeleteEventUsersDispatch({
        type: DELETE_EVENT_USERS_LOADING
    });

    AxiosInstance()
        .post(`/dashboard/events/${EventHash}/users`, {users: GridData, _method: 'delete'})
        .then(Response => {
            setGridData([]);
            setEventUsersModal(false);
            EventsAction(EventsDispatch);
            setAttendantsEmails([]);
            DeleteEventUsersDispatch({
                type: DELETE_EVENT_USERS_SUCCESS,
                payload: Response.data
            });
            toast('Users has been deleted!', {
                autoClose: 3000,
                draggable: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                position: "top-right",
                hideProgressBar: false,
            });
        })
        .catch(Error => {
            DeleteEventUsersDispatch({
                type: DELETE_EVENT_USERS_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API'
            })
            if (Error.response.status === 403) {
                toast("You can't deleted this users because there users taken survey or certificate!", {
                    autoClose: 3000,
                    draggable: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    position: "top-right",
                    hideProgressBar: false,
                });
            }
        });
}

export default DeleteEventUsersAction;
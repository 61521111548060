import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    input: {
        marginBottom: theme.spacing(2),
        '& label': {
            transform: 'translate(30px, 17px) scale(1)',
            '&.MuiInputLabel-shrink': {
                transform: 'translate(30px, -6px) scale(0.75)',
            },
        },
        '& input': {
            padding: '15px 30px',
        },
        '& textarea': {
            padding: '15px 30px',
        },
        '& fieldset': {
            padding: '0 23px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '15px 30px',
        },
        '& .MuiOutlinedInput-multiline': {
            padding: 0,
        },
        '& .MuiFormHelperText-contained': {
            color: theme.palette.error.main,
        },
    },
    modal: {
        top: `50%`,
        left: `50%`,position: 'absolute',
        transform: `translate(-50%, -50%)`,
    },
    colorButton: {
        zIndex: '9',
        right: '22px',
        bottom: '17px',
        height: '30px',
        position: 'absolute',
        boxShadow: '0px 0px 3px 1px #888888',
    },
    titles: {
        margin: 0,
        textTransform: 'uppercase',
    },
    lines: {
        width: '100%',
        margin: '24px',
    },
    dropzone: {
        position: 'relative',
        '& .MuiGrid-spacing-xs-8': {
            margin: '0',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            '& > .MuiGrid-item': {
                padding: '15px',
            }
        },
        '& .MuiGrid-grid-xs-4': {
            width: '100%',
            maxWidth: '100%',
        },
        '& .MuiDropzonePreviewList-image': {
            maxWidth: 'initial',
            height: '70px',
        },
    },
    defImage: {
        top: '80%',
        left: '50%',
        zIndex: '9999',
        maxWidth: '100px',
        maxHeight: '65px',
        position: 'absolute',
        transform: 'translateX(-50%) translateY(-50%)',
    },
    imageError: {
        left: '30px',
        bottom: '-15px',
        position: 'absolute',
    },
}));

export default useStyles;
// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Actions Types
import {SHOW_EVENT_LOADING, SHOW_EVENT_SUCCESS, SHOW_EVENT_ERRORS} from "../../../Types/Dashboard/ShowEventType/ShowEventType";

const ShowEventAction = (EventID) => (ShowEventDispatch) => {

    ShowEventDispatch({
        type: SHOW_EVENT_LOADING
    });

    AxiosInstance()
        .get(`/dashboard/events/${EventID}`)
        .then(Response => {
            ShowEventDispatch({
                type: SHOW_EVENT_SUCCESS,
                payload: Response.data.data
            });
        })
        .catch(Error => (
            ShowEventDispatch({
                type: SHOW_EVENT_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API'
            })
        ));
}

export default ShowEventAction;
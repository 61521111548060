import React, {useContext} from 'react';
import clsx from 'clsx';

// React Router
import { useHistory } from 'react-router-dom';

// Stores
import { DashboardStore } from "../../../Sources/Stores/Dashboard/DashboardStore/DashboardStore";

// Actions
import LogoutDashboardAction from "../../../Sources/Actions/Dashboard/LogoutDashboardAction/LogoutDashboardAction";

// Material-UI Components
import {AppBar, Toolbar, IconButton, Typography, Box, CircularProgress} from '@material-ui/core';

// Material-UI Components
import {ExitToApp, Menu} from '@material-ui/icons';

// Component Styles
import useStyles from './DashboardHeader_Styles';

const DashboardHeader = () => {
    const classes = useStyles();
    const History = useHistory();
    const { DashSideMenu, setDashSideMenu, LogoutDashboardState: {LogoutDashboard: {Loading}}, LogoutDashboardDispatch } = useContext(DashboardStore);

    // Side Menu
    const handleDrawerOpen = () => {
        setDashSideMenu(true);
    };

    // Logout
    const DashboardLogout = () => {
        LogoutDashboardAction(History)(LogoutDashboardDispatch)
    }

    return (
        <React.Fragment>
            <AppBar position="fixed" className={clsx(classes.appBar, {[classes.appBarShift]: DashSideMenu})}>
                <Toolbar className={classes.toolbar}>
                    <Box className={classes.toolbar}>
                        <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={clsx(classes.menuButton, {[classes.hide]: DashSideMenu})}>
                            <Menu />
                        </IconButton>
                        <Typography variant="h6" className={clsx({[classes.hide]: DashSideMenu})} noWrap>
                            CME
                        </Typography>
                    </Box>
                    <IconButton color="inherit" aria-label="open drawer" disabled={Loading} onClick={DashboardLogout} edge="start">
                        {(Loading)? <CircularProgress /> : <ExitToApp />}
                    </IconButton>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

export default DashboardHeader;
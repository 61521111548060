import React, {useState} from 'react';

// Material-UI Components
import {Paper, Typography, Grid, FormControlLabel, Radio, RadioGroup, Button} from '@material-ui/core';

// Material-UI Icons
import {Delete} from '@material-ui/icons';

// Components
import MultipleChoices from "./QuestionsTypes/MultipleChoices/MultipleChoices";

// Component Style
import useStyles from './Questions_Styles';

const Questions = (props) => {
    const classes = useStyles();
    const [QuestionType, setQuestionType] = useState('1');

    const RemoveQuestion = (Index) => {
        const Question = [...props.Values.survey_questions];
        Question.splice(Index, 1);
        props.SetValues({...props.Values, survey_questions: Question})
    };

    return (
        <React.Fragment>
            <Paper elevation={0} className={classes.root}>
                <Grid container spacing={3}>
                    <Grid className={classes.titleContent} item md={2}>
                        <Typography className={classes.titles} variant="h6" display="block" gutterBottom>
                            Question {props.Index + 1}
                        </Typography>
                    </Grid>
                    <Grid className={classes.titleContent} item md={10}>
                        <RadioGroup row defaultValue="position" name="survey_type" value={QuestionType} onChange={(e) => setQuestionType(e.target.value)}>
                            <FormControlLabel value="1" control={<Radio />} label="Multiple Choices" />
                        </RadioGroup>
                        {(props.Values.survey_questions.length > 1) &&
                            <Button startIcon={<Delete />} variant="outlined" color="secondary" onClick={() => RemoveQuestion(props.Index)}>
                                Remove
                            </Button>
                        }
                    </Grid>
                    {QuestionType === '1' && <MultipleChoices {...props} /> }
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

export default Questions;
// API Instance
import AxiosInstance from "../../../../Database/Views/AxiosInstance";

// Notifications
import {toast} from 'react-toastify';

// Actions Types
import {CERTIFICATE_ERRORS, CERTIFICATE_LOADING, CERTIFICATE_SUCCESS} from "../../../Types/Views/CertificateType/CertificateType";

const CertificateAction = (EventID) => (CertificatePDF) => (CertificateDispatch) => (Type) => {

    CertificateDispatch({
        type: CERTIFICATE_LOADING,
    });

    AxiosInstance()
        .post(`/events/${EventID}/certificates`, CertificatePDF)
        .then(Response => {
            CertificateDispatch({
                type: CERTIFICATE_SUCCESS,
                payload: Response.data,
            });

            Type.id === 1 && toast('Certificate Was Sent To Your Email As PDF File!', {
                autoClose: 3000,
                draggable: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                position: "top-right",
                hideProgressBar: false,
            })
        })
        .catch(Error => (
            CertificateDispatch({
                type: CERTIFICATE_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
        ));

}

export default CertificateAction;
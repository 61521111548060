// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Notifications
import {toast} from 'react-toastify';

// Actions Types
import {FORGOT_PASSWORD_ERRORS, FORGOT_PASSWORD_LOADING, FORGOT_PASSWORD_SUCCESS} from "../../../Types/Dashboard/ForgotPasswordDashboardType/ForgotPasswordDashboardType";

const ForgotPasswordDashboardAction = (History) => ({email}) => (ForgotPasswordDashboardDispatch) => {

    ForgotPasswordDashboardDispatch({
        type: FORGOT_PASSWORD_LOADING,
    })

    AxiosInstance()
        .post(`/dashboard/forget-password`, {email})
        .then(Response => {
            ForgotPasswordDashboardDispatch({
                type: FORGOT_PASSWORD_SUCCESS,
                payload: Response.data,
            });
            History.push('./login');
            toast(`${Response.data.message}`, {
                autoClose: 3000,
                draggable: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                position: "top-right",
                hideProgressBar: false,
            });
        })
        .catch(Error => {
            ForgotPasswordDashboardDispatch({
                type: FORGOT_PASSWORD_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
        });

}

export default ForgotPasswordDashboardAction;
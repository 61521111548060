// Actions Types
import {EDIT_CLIENT_LOADING, EDIT_CLIENT_SUCCESS, EDIT_CLIENT_ERRORS} from "../../Types/Dashboard/EditClientType/EditClientType";

const EditClientReducers = (state, {payload, type}) => {
    switch (type) {
        case EDIT_CLIENT_LOADING :
            return {
                ...state,
                EditClient: {
                    ...state.EditClient,
                    Loading: true,
                    Error: false
                }
            }
        case EDIT_CLIENT_SUCCESS :
            return {
                ...state,
                EditClient: {
                    ...state.EditClient,
                    Loading: false,
                    Data: payload,
                }
            }
        case EDIT_CLIENT_ERRORS :
            return {
                ...state,
                EditClient: {
                    ...state.EditClient,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default EditClientReducers;
import {useContext, useState} from 'react';

// React Router
import { useHistory } from 'react-router-dom';

// Store Provider
import { DashboardStore } from '../../../Sources/Stores/Dashboard/DashboardStore/DashboardStore';

// Actions
import NewEventAction from '../../../Sources/Actions/Dashboard/NewEventAction/NewEventAction';

// Forms Validations
import FormValidation from '../../Validation/FormsValidations';

const NewEventFunctions = () => {
    const History = useHistory();
    const [FormValues, setFormValues] = useState({});
    const [FormErrors, setFormErrors] = useState({});
    const {NewEventState: {NewEvent: {Loading, Error, Data}}, NewEventDispatch} = useContext(DashboardStore);

    // Fields Validation
    const FieldsValidation = {
        client_id: {},
        name : {
            error: "",
            minLength: 3,
            maxLength: 20,
        },
        status_id: {},
        main_color: {},
        title_color: {},
        font_color: {},
        survey_type: {},
        survey_questions: {},
        start_date: {},
        end_date: {},
    };

    // Form Format
    //============
    const FormFormat = new FormData();

    /* Global */
    for (const Data in FormValues) {
        FormFormat.append(`${Data}`, FormValues[Data]);
    }

    /* Attendees File */
    const AttendeesFile = FormValues.attendees && FormValues.attendees.file;
    AttendeesFile && FormFormat.append('attendees[file]', AttendeesFile);
    FormFormat.delete('attendees');

    /* Survey Type */
    const SurveyType = FormValues.survey_type;
    SurveyType && FormFormat.append('survey[type]', SurveyType);

    /* Survey Type */
    const SurveyQuestions = FormValues.survey_questions;
    SurveyQuestions && SurveyQuestions.forEach((Question,Index) => {
        FormFormat.append(`survey[questions][${Index}][content]`, Question.question);
        Question.answers.forEach((Answer) => {
            FormFormat.append(`survey[questions][${Index}][answers][]`, Answer);
        });
    });

    // Form Event
    const FormEvent = (event) => {
        const { target: { value, name } } = event;

        // Set Values
        setFormValues((FormVal) => ({...FormVal, [name]: value}));

        // Set Errors
        const error = FormValidation(name, value, FieldsValidation) || '';
        setFormErrors({...FormErrors, [name]: error});
    };

    // Form Validation
    const FormValid =
        !FormValues.client_id ||
        !FormValues.name?.length ||
        !FormValues.status_id ||
        !FormValues.main_color?.length ||
        !FormValues.title_color?.length ||
        !FormValues.font_color?.length ||
        !FormValues.logo ||
        !FormValues.background ||
        !FormValues.favicon ||
        !FormValues.attendees.file ||
        !FormValues.start_date?.length ||
        !FormValues.end_date?.length ||
        !(FormValues.date === '0') ||
        !FormValues.certificate;

    // Form Submit
    const FormSubmit = () => {
        NewEventAction(History)(FormFormat)(NewEventDispatch);
    }

    return {FormValues, setFormValues, FormErrors, FormEvent, FormValid, FormSubmit, Loading, Error, Data};
}

export default NewEventFunctions;
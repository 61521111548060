import axios from 'axios';

const AxiosInstance = (History = null) => {
    const BaseURL = process.env.REACT_APP_DATABASE_URL;
    const Token   = 'Q8YS3VHAGQXSP8H4G';
    let   Headers = {};

    if (localStorage[Token]) {
        Headers.Authorization = `Bearer ${localStorage[Token]}`;
    }

    const AxiosCreation = axios.create({
        baseURL: BaseURL,
        headers: Headers,
    })

    AxiosCreation.interceptors.response.use(
        (Response) =>
            new Promise((Resolve) => {
                Resolve(Response);
            }),
        (Error) => {
            if (Error.response.status === 401) {
                localStorage.removeItem(Token);
                if (History) {
                    History.push('/dashboard/events');
                } else {
                    window.location = '/dashboard/events';
                }
            } else {
                return new Promise((Resolve, Reject) => {
                    Reject(Error);
                })
            }
        }
    )
    return AxiosCreation;
}

export default AxiosInstance;
// API Instance
import AxiosInstance from "../../../../Database/Views/AxiosInstance";

const CitiesAction = (CountryID) => (setCities) => {

    AxiosInstance()
        .get(`countries/${CountryID}/cities`)
        .then(Response => {
            setCities(Response.data.data);
        })
        .catch(Error => {
            console.log(Error);
        });

}

export default CitiesAction;
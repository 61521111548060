// Actions Types
import {CERTIFICATE_LOADING, CERTIFICATE_SUCCESS, CERTIFICATE_ERRORS} from "../../Types/Views/CertificateType/CertificateType";

const CertificateReducers = (state, {payload, type}) => {
    switch (type) {
        case CERTIFICATE_LOADING :
            return {
                ...state,
                Certificate: {
                    ...state.Certificate,
                    Loading: true,
                    Error: false
                }
            }
        case CERTIFICATE_SUCCESS :
            return {
                ...state,
                Certificate: {
                    ...state.Certificate,
                    Loading: false,
                    Data: payload,
                }
            }
        case CERTIFICATE_ERRORS :
            return {
                ...state,
                Certificate: {
                    ...state.Certificate,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default CertificateReducers;
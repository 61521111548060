// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Actions Types
import {CLIENTS_LOADING, CLIENTS_SUCCESS, CLIENTS_ERRORS} from "../../../Types/Dashboard/ClientsType/ClientsType";

const ClientsAction = (ClientsDispatch) => {

    ClientsDispatch({
        type: CLIENTS_LOADING
    });

    AxiosInstance()
        .get(`/dashboard/clients`)
        .then(Response => {
            ClientsDispatch({
                type: CLIENTS_SUCCESS,
                payload: Response.data
            });
        })
        .catch(Error => (
            ClientsDispatch({
                type: CLIENTS_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API'
            })
        ));
}

export default ClientsAction;
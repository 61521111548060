// Actions Types
import {LOGIN_LOADING, LOGIN_SUCCESS, LOGIN_ERRORS} from "../../Types/Dashboard/LoginDashboardType/LoginDashboardType";

const LoginDashboardReducers = (state, {payload, type}) => {
    switch (type) {
        case LOGIN_LOADING :
            return {
                ...state,
                LoginDashboard: {
                    ...state.LoginDashboard,
                    Loading: true,
                    Error: false
                }
            }
        case LOGIN_SUCCESS :
            return {
                ...state,
                LoginDashboard: {
                    ...state.LoginDashboard,
                    Loading: false,
                    Data: payload,
                }
            }
        case LOGIN_ERRORS :
            return {
                ...state,
                LoginDashboard: {
                    ...state.LoginDashboard,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default LoginDashboardReducers;
import React, {useContext} from 'react';
import clsx from 'clsx';

// React Router
import { Link, useLocation, useParams } from 'react-router-dom';

// Stores
import { DashboardStore } from "../../../Sources/Stores/Dashboard/DashboardStore/DashboardStore";

// Material-UI Components
import { Drawer, Box, IconButton, List, ListItem, ListItemText, Typography, ListItemIcon, Divider } from '@material-ui/core';

// Material-UI Components
import {Dashboard, Person, PersonAdd, Menu, AddToQueue, Airplay} from '@material-ui/icons';

// Component Styles
import useStyles from './DashboardSideMenu_Styles';

const DashboardSideMenu = () => {
    const classes = useStyles();
    const Location = useLocation();
    const { ClientID, EventID } = useParams();
    const { DashSideMenu, setDashSideMenu } = useContext(DashboardStore);

    // Side Menu
    const handleDrawerClose = () => {
        setDashSideMenu(false);
    };

    // Side Menu Active
    const ActiveDashboard = (Location.pathname === '/dashboard');
    const ActiveClients = (Location.pathname === '/dashboard/clients' || Location.pathname === `/dashboard/clients/${ClientID}` || Location.pathname === `/dashboard/clients/${ClientID}/edit`);
    const ActiveNewClient = (Location.pathname === '/dashboard/clients/create');
    const ActiveEvents = (Location.pathname === '/dashboard/events' || Location.pathname === `/dashboard/events/${EventID}` || Location.pathname === `/dashboard/events/${EventID}/edit`);
    const ActiveNewEvents = (Location.pathname === '/dashboard/events/create');

    return (
        <React.Fragment>
            <Drawer variant="permanent" className={clsx(classes.drawer, {[classes.drawerOpen]: DashSideMenu, [classes.drawerClose]: !DashSideMenu})}
                    classes={{paper: clsx({[classes.drawerOpen]: DashSideMenu, [classes.drawerClose]: !DashSideMenu}) }}>

                {/* Brand Logo */}
                <Box className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose} className={clsx(classes.menuButton, {[classes.hide]: !DashSideMenu})}>
                        <Menu />
                    </IconButton>
                    <Typography variant="h6" className={clsx(classes.textLogo, {[classes.hide]: !DashSideMenu})} gutterBottom>
                        CME
                    </Typography>
                </Box>

                {/* List Menu */}
                <List>
                    {/* Dashboard */}
                    <ListItem button component={Link} to="/dashboard" className={classes.itemButton} selected={ActiveDashboard}>
                        <ListItemIcon className={classes.slideButton}>
                            <Dashboard />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <Divider />

                    {/* Clients */}
                    <ListItem button component={Link} to="/dashboard/clients" className={classes.itemButton} selected={ActiveClients}>
                        <ListItemIcon className={classes.slideButton}>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary="Clients List" />
                    </ListItem>
                    <ListItem button component={Link} to="/dashboard/clients/create" className={classes.itemButton} selected={ActiveNewClient}>
                        <ListItemIcon className={classes.slideButton}>
                            <PersonAdd />
                        </ListItemIcon>
                        <ListItemText primary="New Client" />
                    </ListItem>
                    <Divider />

                    {/* Events */}
                    <ListItem button component={Link} to="/dashboard/events" className={classes.itemButton} selected={ActiveEvents}>
                        <ListItemIcon className={classes.slideButton}>
                            <Airplay />
                        </ListItemIcon>
                        <ListItemText primary="Events List" />
                    </ListItem>
                    <ListItem button component={Link} to="/dashboard/events/create" className={classes.itemButton} selected={ActiveNewEvents}>
                        <ListItemIcon className={classes.slideButton}>
                            <AddToQueue />
                        </ListItemIcon>
                        <ListItemText primary="New Event" />
                    </ListItem>
                </List>
            </Drawer>
        </React.Fragment>
    );
};

export default DashboardSideMenu;
// Actions Types
import {NEW_CLIENT_LOADING, NEW_CLIENT_SUCCESS, NEW_CLIENT_ERRORS} from "../../Types/Dashboard/NewClientType/NewClientType";

const NewClientReducers = (state, {payload, type}) => {
    switch (type) {
        case NEW_CLIENT_LOADING :
            return {
                ...state,
                NewClient: {
                    ...state.NewClient,
                    Loading: true,
                    Error: false
                }
            }
        case NEW_CLIENT_SUCCESS :
            return {
                ...state,
                NewClient: {
                    ...state.NewClient,
                    Loading: false,
                    Data: payload,
                }
            }
        case NEW_CLIENT_ERRORS :
            return {
                ...state,
                NewClient: {
                    ...state.NewClient,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default NewClientReducers;
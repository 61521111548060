// API Instance
import AxiosInstance from "../../../../Database/Views/AxiosInstance";

// Actions Types
import {SKIP_SURVEY_LOADING, SKIP_SURVEY_SUCCESS, SKIP_SURVEY_ERRORS} from "../../../Types/Views/SkipSurveyType/SkipSurveyType";

const SkipSurveyAction = (History) => (SurveyID) => (SkipSurveyDispatch) => {

    SkipSurveyDispatch({
        type: SKIP_SURVEY_LOADING,
    })

    AxiosInstance()
        .post(`/surveys/${SurveyID}/skip`)
        .then(Response => {
            SkipSurveyDispatch({
                type: SKIP_SURVEY_SUCCESS,
                payload: Response.data.data,
            })
            History.push('./certificate');
        })
        .catch(Error => (
            SkipSurveyDispatch({
                type: SKIP_SURVEY_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
        ));

}

export default SkipSurveyAction;
import React, {useEffect, useState, useContext} from 'react';

// Operations
import NewEventFunction from "../../../../Functions/Dashboard/NewEvent/NewEventFunction";

// Actions
import ClientsAction from "../../../../Sources/Actions/Dashboard/ClientsAction/ClientsAction";

// Stores
import { DashboardStore } from "../../../../Sources/Stores/Dashboard/DashboardStore/DashboardStore";

// Material-UI Components
import {Typography, Paper, Grid, Stepper, Box, Button, CircularProgress, Step, StepLabel} from '@material-ui/core';

// Material-UI Icons
import { Save } from '@material-ui/icons';

// Steps Components
import EventStep from './NewEventSteps/EventStep/EventStep';
import SurveyStep from './NewEventSteps/SurveyStep/SurveyStep';
import CertificateStep from './NewEventSteps/CertificateStep/CertificateStep';

// Component Style
import useStyles from './NewEventLayout_Styles';

const NewEventLayout = () => {
    const classes = useStyles();
    const [ActiveStep, setActiveStep] = useState(0);
    const {ClientsState: {Clients: {Data}}, ClientsDispatch} = useContext(DashboardStore);
    const {FormValues, setFormValues, FormErrors, FormEvent, FormValid, FormSubmit, Loading, Error} = NewEventFunction();

    // Step titles
    const Labels = ["Event", "Survey", "Certificate"];

    // Proceed To Next Step
    const NextStep = () => {
        setActiveStep((prev) => prev + 1)
    };

    // Go Back To Prev Step
    const BackStep = () => {
        setActiveStep((prev) => prev - 1)
    };

    // Get Clients
    useEffect(() => {
        ClientsAction(ClientsDispatch);
    }, [ClientsDispatch])

    const StepsContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <EventStep Next={NextStep} Values={FormValues} SetValues={setFormValues} Errors={FormErrors} Event={FormEvent} DataError={Error} Clients={Data} />
                );
            case 1:
                return (
                    <SurveyStep Next={NextStep} Back={BackStep} Values={FormValues} SetValues={setFormValues} Errors={FormErrors} Event={FormEvent} DataError={Error} />
                );
            case 2:
                return (
                    <CertificateStep Back={BackStep} Values={FormValues} SetValues={setFormValues} Errors={FormErrors} Event={FormEvent} DataError={Error} />
                );
            default:
                break;
        }
    };

    return (
        <React.Fragment>
            <Box className={classes.header}>
                <Typography className={classes.title} variant="h5" gutterBottom>
                    New Event
                    <Box component={'span'}>Create New Event in CME Certificate System.</Box>
                </Typography>
                <Button startIcon={<Save />} onClick={FormSubmit} checked={false} disabled={FormValid || Loading} variant="contained" disableElevation color="primary">
                    {(Loading) ? <CircularProgress /> : <React.Fragment>Save</React.Fragment>}
                </Button>
            </Box>
            <form noValidate autoComplete="off">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Stepper activeStep={ActiveStep} alternativeLabel>
                            {Labels.map((Label) => (
                                <Step key={Label}>
                                    <StepLabel>{(ActiveStep.number === Label) ? '' : Label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </Grid>
                <Paper elevation={3} className={classes.root}>
                    {StepsContent(ActiveStep)}
                </Paper>
            </form>
        </React.Fragment>
    );
};

export default NewEventLayout;
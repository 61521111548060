import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '25px 0',
        margin: '0',
        alignItems: 'center',
    },
    brandLogo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& img': {
            maxWidth: '130px',
            maxHeight: '60px',
            [theme.breakpoints.down('md')]: {
                maxWidth: '60%',
            },
        }
    },
    eventLogo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& img': {
            maxWidth: '130px',
            maxHeight: '60px',
            [theme.breakpoints.down('md')]: {
                maxWidth: '60%',
            },
        }
    }
}));

export default useStyles;
import React, {useContext, useEffect, useState} from 'react';

// React Router
import { Redirect, Route, Switch, useParams, useHistory } from "react-router-dom";

// Store Provider
import { ViewsStore } from "../../../Sources/Stores/Views/ViewsStore/ViewsStore";

// Actions
import EventAction from "../../../Sources/Actions/Views/EventAction/EventAction";
import EventViewsAction from "../../../Sources/Actions/Views/EventViewsAction/EventViewsAction";
import CountriesActions from "../../../Sources/Actions/Views/ProfileViewsAction/CountriesAction";
import UserInfoViewsAction from "../../../Sources/Actions/Views/UserInfoViewsAction/UserInfoViewsAction";
import CertificateViewsAction from "../../../Sources/Actions/Views/CertificateViewsAction/CertificateViewsAction";

// Middlewares
import AuthViewsMiddle from "../../../Middlewares/Views/AuthViewsMiddle";

// Material-UI Components
import { Box } from "@material-ui/core";

// Components
import HeaderComponent from "../../../Components/Views/HeaderComponent/HeaderComponent";

// Errors
import EventLoadingContainers from "../../Errors/EventLoading/EventLoadingContainers";
import EventNotExistContainers from "../../Errors/EventNotExist/EventNotExistContainers";

// Layouts
import LoginLayout from "../../../Layouts/Views/LoginLayout/LoginLayout";
import SurveyLayout from "../../../Layouts/Views/SurveyLayout/SurveyLayout";
import ProfileLayout from "../../../Layouts/Views/ProfileLayout/ProfileLayout";
import CertificateLayout from "../../../Layouts/Views/CertificateLayout/CertificateLayout";

// Component Style
import useStyles from './FrontViewContainer_Styles';

const FrontViewContainer = () => {
    const classes = useStyles();
    const { EventID } = useParams();
    const { History } = useHistory();
    const [ EventState, setEventState ] = useState(true);
    const [ CertificateState, setCertificateState ] = useState(true);
    const { EventState: {Event: {Data: EventData}}, EventDispatch,
            EventViewsState: {EventViews: {Loading, Error, Data}}, EventViewsDispatch,
            CertificateViewsState: {CertificateViews: {Loading: CertLoading, Data: CertData}}, CertificateViewsDispatch,
            UserViewsState, UserViewsDispatch, setCountries} = useContext(ViewsStore);

    const UserState = UserViewsState.UserViews;
    const User      = UserState.Data;

    useEffect(() => {
        EventViewsAction(EventID)(EventViewsDispatch)
    }, [EventID, EventViewsDispatch]);

    useEffect(() => {
        if (Data.favicon) {
            const Favicon = document.getElementById("favicon");
            Favicon.href = `${Data.favicon}`;
        }
    }, [Data]);

    useEffect( () => {
        if (AuthViewsMiddle(EventID)) {
            UserInfoViewsAction(UserViewsDispatch)
        }
    }, [UserViewsDispatch, EventID]);

    useEffect( () => {
        if (AuthViewsMiddle(EventID) && !User.profile && UserState.Loading) {
            CountriesActions(setCountries);
        }
    }, [User.profile, UserState.Loading, setCountries, EventID]);

    useEffect( () => {
        if (AuthViewsMiddle(EventID) && User.profile && EventState) {
            EventAction(History)(EventID)(EventDispatch)
            setEventState(false);
        }
    }, [EventDispatch, EventID, User.profile, EventState, History]);

    useEffect( () => {
        if (AuthViewsMiddle(EventID) && User.profile && Object.keys(EventData).length !== 0 && CertificateState && ((!EventData.survey) || (EventData.survey && EventData.survey.is_taken))) {
            CertificateViewsAction(EventID)(CertificateViewsDispatch)
            setCertificateState(false);
        }
    }, [CertificateState, CertificateViewsDispatch, EventID, User.profile, EventData]);

    return (
        <React.Fragment>
            { Loading &&
                <EventLoadingContainers />
            } { Object.keys(Data).length !== 0 &&
                <Box className={classes.root} style={{backgroundImage: `url(${Data.background})`}}>
                    <HeaderComponent event={Data} />
                    <Switch>
                        <React.Fragment>
                            { AuthViewsMiddle(EventID) ?
                                <React.Fragment>
                                    {/* Profile */}
                                    { !User.profile && !UserState.Loading &&
                                        <React.Fragment>
                                            <Redirect to={`/events/${EventID}/profile`}/>
                                            <Route exact path={"/events/:EventID/profile"} component={() => <ProfileLayout event={Data}/>}/>
                                        </React.Fragment>
                                    }

                                    { (User.profile && Object.keys(EventData).length !== 0) &&
                                        <React.Fragment>
                                            {/* Survey */}
                                            { (EventData.survey && !EventData.survey.is_taken) &&
                                                <React.Fragment>
                                                    <Redirect to={`/events/${EventID}/survey`}/>
                                                    <Route exact path ={"/events/:EventID/survey"} component={() => <SurveyLayout event={Data} survey={EventData.survey} />} />
                                                </React.Fragment>
                                            }

                                            {/* Certificate */}
                                            { ((!EventData.survey) || (EventData.survey && EventData.survey.is_taken)) &&
                                                <React.Fragment>
                                                    <Redirect to={`/events/${EventID}/certificate`}/>
                                                    <Route exact path={"/events/:EventID/certificate"} component={() => <CertificateLayout style={Data} profile={User.profile} Loading={CertLoading} Certificate={CertData.image} />}/>
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment> :
                                <React.Fragment>
                                    <Redirect to={`/events/${EventID}`} />
                                    <Route exact path ={"/events/:EventID"} component={() => <LoginLayout event={Data} />} />
                                </React.Fragment>
                            }
                        </React.Fragment>
                    </Switch>
                </Box>
            } { Error &&
                <EventNotExistContainers />
            }
        </React.Fragment>
    );
};

export default FrontViewContainer;
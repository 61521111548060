import React, {useEffect, useState} from 'react';

// Material-UI Components
import {Typography, Grid, TextField, Button, MenuItem, Modal, Box, Divider} from '@material-ui/core';

// Material-UI Icons
import {SystemUpdateAlt} from '@material-ui/icons';

// Color Picker
import {SketchPicker} from 'react-color';

// Image Uploader
import {DropzoneArea} from 'material-ui-dropzone';

// Component Style
import useStyles from './EventStep_Styles';

const EventStep = ({Clients, Next, Values, SetValues, Errors, Event, DataError}) => {
    const classes = useStyles();
    const [ EventColor, setEventColor ] = useState(Values.main_color || '#000000');
    const [ TitleColor, setTitleColor ] = useState(Values.title_color || '#6f6f6f');
    const [ FontColor, setFontColor ] = useState(Values.font_color || '#FFFFFF');
    const [ ShowEventColor, setShowEventColor ] = useState(false);
    const [ ShowFontColor, setShowFontColor ] = useState(false);
    const [ ShowTitleColor, setShowTitleColor ] = useState(false);
    const [ AttendeesFile, setAttendeesFile ] = useState(Values.attendees?.file || null);
    const IsValid = Values.client_id && (!Errors.client_id) &&
                    (Values.name && Values.name.length > 0) && (!Errors.name) &&
                    Values.status_id && (!Errors.status_id) &&
                    (Values.main_color && Values.main_color.length > 0) && (!Errors.main_color) &&
                    (Values.font_color && Values.font_color.length > 0) && (!Errors.font_color) &&
                    (Values.title_color && Values.title_color.length > 0) && (!Errors.title_color) &&
                    (Values.logo) &&
                    (Values.background) &&
                    (Values.favicon) &&
                    (Values.attendees?.file);

    const EventColorModal = () => {
        setShowEventColor(false);
    };

    const TitleColorModal = () => {
        setShowTitleColor(false);
    };

    const FontColorModal = () => {
        setShowFontColor(false);
    };

    useEffect(() => {
        SetValues((Values) => ({...Values, main_color: EventColor}));
        SetValues((Values) => ({...Values, title_color: TitleColor}));
        SetValues((Values) => ({...Values, font_color: FontColor}));
        SetValues((Values) => ({...Values, attendees: {file: !!AttendeesFile && AttendeesFile}}));
    }, [AttendeesFile, EventColor, FontColor, SetValues, TitleColor]);

    const deleteFromState = (DelValue) => {
        const { [DelValue]: value, ...reset } = Values;
        SetValues(reset);
    }

    const AttendantFile = (files) => {
        files[0] && setAttendeesFile(files[0]);
    }

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography className={classes.titles} variant="h5" display="block" gutterBottom>
                        Event
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField fullWidth value={Values.name || ''} className={classes.input} onChange={Event} type="text" name="name" label="Event Name" variant="outlined" error={!!Errors.name} helperText={Errors.name} required />
                    {(DataError && DataError.errors && !Errors.name) &&
                    <Typography variant="caption" display="block" color={"error"} gutterBottom>
                        {DataError.errors.name}
                    </Typography>
                    }
                </Grid>
                <Grid item md={3} xs={12}>
                    <TextField fullWidth value={Values.client_id || ''} className={classes.input} onChange={Event} select name="client_id" label="Client Name" variant="outlined" error={!!Errors.client_id} helperText={Errors.client_id} required>
                        {Clients.data ? Clients.data.map((Client) => (
                                <MenuItem key={Client.id} value={Client.id}>
                                    {Client.name}
                                </MenuItem>))
                            :
                            <MenuItem disabled>No Clients Available</MenuItem>
                        }
                    </TextField>
                    {(DataError && DataError.errors && !Errors.client_id) &&
                        <Typography variant="caption" display="block" color={"error"} gutterBottom>
                            {DataError.errors.client_id}
                        </Typography>
                    }
                </Grid>
                <Grid item md={3} xs={12}>
                    <TextField fullWidth value={Values.status_id || ''} className={classes.input} onChange={Event} select type="text" name="status_id" label="Status" variant="outlined" error={!!Errors.status_id} helperText={Errors.status_id} required>
                        <MenuItem value={'1'}>Pending</MenuItem>
                        <MenuItem value={'2'}>Published</MenuItem>
                    </TextField>
                    {(DataError && DataError.errors && !Errors.status_id) &&
                        <Typography variant="caption" display="block" color={"error"} gutterBottom>
                            {DataError.errors.status_id}
                        </Typography>
                    }
                </Grid>
                <Grid item md={2} xs={4}>
                    <Box style={{position: 'relative'}}>
                        <Button className={classes.colorButton} variant="contained" style={{backgroundColor: EventColor}} onClick={() => setShowEventColor(!ShowEventColor)}> </Button>
                        <TextField fullWidth disabled value={EventColor} className={classes.input} onChange={Event} name="main_color" label="Event Color *" variant="outlined" error={!!Errors.main_color} helperText={Errors.main_color} />
                        <Modal open={ShowEventColor} onClose={EventColorModal}>
                            <Box className={classes.modal}>
                                <SketchPicker color={EventColor} onChange={(color) => setEventColor(color.hex)} />
                            </Box>
                        </Modal>
                        {(DataError && DataError.errors && !Errors.main_color) &&
                            <Typography variant="caption" display="block" color={"error"} gutterBottom>
                                {DataError.errors.main_color}
                            </Typography>
                        }
                    </Box>
                </Grid>
                <Grid item md={2} xs={4}>
                    <Box style={{position: 'relative'}}>
                        <Button className={classes.colorButton} variant="contained" style={{backgroundColor: TitleColor}} onClick={() => setShowTitleColor(!ShowTitleColor)}> </Button>
                        <TextField fullWidth disabled value={TitleColor} className={classes.input} onChange={Event} name="title_color" label="Title Color *" variant="outlined" error={!!Errors.title_color} helperText={Errors.title_color} />
                        <Modal open={ShowTitleColor} onClose={TitleColorModal}>
                            <Box className={classes.modal}>
                                <SketchPicker color={TitleColor} onChange={(color) => setTitleColor(color.hex)} />
                            </Box>
                        </Modal>
                        {(DataError && DataError.errors && !Errors.title_color) &&
                            <Typography variant="caption" display="block" color={"error"} gutterBottom>
                                {DataError.errors.title_color}
                            </Typography>
                        }
                    </Box>
                </Grid>
                <Grid item md={2} xs={4}>
                    <Box style={{position: 'relative'}}>
                        <Button className={classes.colorButton} variant="contained" style={{backgroundColor: FontColor}} onClick={() => setShowFontColor(!ShowFontColor)}> </Button>
                        <TextField fullWidth disabled value={FontColor || ''} className={classes.input} onChange={Event} name="font_color" label="Font Color *" variant="outlined" error={!!Errors.font_color} helperText={Errors.font_color} />
                        <Modal open={ShowFontColor} onClose={FontColorModal}>
                            <Box className={classes.modal}>
                                <SketchPicker color={FontColor} onChange={(color) => setFontColor(color.hex)} />
                            </Box>
                        </Modal>
                        {(DataError && DataError.errors && !Errors.font_color) &&
                            <Typography variant="caption" display="block" color={"error"} gutterBottom>
                                {DataError.errors.font_color}
                            </Typography>
                        }
                    </Box>
                </Grid>
                <Grid className={classes.dropzone} item md={2} xs={4}>
                    <DropzoneArea {...!!Values.logo && {initialFiles: [Values.logo]}} showAlerts={['error']} maxFileSize={5000000} inputProps={{name: "logo"}} filesLimit={1} acceptedFiles={['image/*']} onDelete={() => deleteFromState('logo')} onChange={(files) => files[0] && SetValues({...Values, logo: files[0]})} dropzoneText={"Upload Event Logo *"} />
                    {(DataError && DataError.errors && !Errors.logo) &&
                        <Typography variant="caption" display="block" color={"error"} gutterBottom>
                            {DataError.errors.logo}
                        </Typography>
                    }
                </Grid>
                <Grid className={classes.dropzone} item md={2} xs={4}>
                    <DropzoneArea {...!!Values.background && {initialFiles: [Values.background]}} showAlerts={['error']} maxFileSize={5000000} inputProps= {{name: "background"}} filesLimit={1} acceptedFiles={['image/*']} onDelete={() => deleteFromState('background')} onChange={(files) => files[0] && SetValues({...Values, background: files[0]})} dropzoneText={"Upload Event Background *"} />
                    {(DataError && DataError.errors && !Errors.background) &&
                        <Typography variant="caption" display="block" color={"error"} gutterBottom>
                            {DataError.errors.background}
                        </Typography>
                    }
                </Grid>
                <Grid className={classes.dropzone} item md={2} xs={4}>
                    <DropzoneArea {...!!Values.favicon && {initialFiles: [Values.favicon]}} showAlerts={['error']} maxFileSize={5000000} inputProps= {{name: "favicon"}} filesLimit={1} acceptedFiles={['image/*']} onDelete={() => deleteFromState('favicon')} onChange={(files) => files[0] && SetValues({...Values, favicon: files[0]})} dropzoneText={"Upload Event Favicon *"} />
                    {(DataError && DataError.errors && !Errors.favicon) &&
                        <Typography variant="caption" display="block" color={"error"} gutterBottom>
                            {DataError.errors.favicon}
                        </Typography>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.lines} />
                    <Typography className={classes.titles} variant="h5" display="block" gutterBottom>
                        Attendants
                    </Typography>
                </Grid>
                <Grid className={classes.download} item md={4} xs={12}>
                    <Button fullWidth href={'../../Download/attendants.xlsx'} variant="contained" color="primary" size="large" download startIcon={<SystemUpdateAlt />}>
                        Download Attendants Example Sheet
                    </Button>
                </Grid>
                <Grid className={classes.dropzone} item md={3} xs={12}>
                    <DropzoneArea {...!!Values.attendees?.file && {initialFiles: [Values.attendees.file]}} showAlerts={['error']} maxFileSize={5000000} inputProps={{name: "attendees[file]"}} filesLimit={1}
                        acceptedFiles={[ 'application/vnd.ms-excel', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'xls', 'text/x-csv', 'application/csv', 'application/x-csv', 'text/comma-separated-values', 'text/x-comma-separated-values', 'text/tab-separated-values']}
                        onDelete={() => {
                            setAttendeesFile(null)
                            deleteFromState('attendees.file')
                        }} onChange={AttendantFile}
                        dropzoneText={"Upload Attendants List"} />
                    {(DataError && DataError.errors && Errors.attendees && !Errors.attendees.file) &&
                        <Typography variant="caption" display="block" color={"error"} gutterBottom>
                            {DataError.errors.attendees.file}
                        </Typography>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                        <Button variant="contained" disabled={!IsValid} color="primary" onClick={IsValid ? Next : null}>
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default EventStep;
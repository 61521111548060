import React, {useContext, useEffect} from 'react';

// Stores
import { DashboardStore } from "../../../../Sources/Stores/Dashboard/DashboardStore/DashboardStore";

// Actions
import ShowClientAction from "../../../../Sources/Actions/Dashboard/ShowClientAction/ShowClientAction";

// React Router
import { useParams } from 'react-router-dom';

// Operations
import EditClientFunction from "../../../../Functions/Dashboard/EditClient/EditClientFunction";

// Material-UI Components
import {Typography, Paper, Grid, TextField, Box, Button, CircularProgress} from '@material-ui/core';

// Material-UI Icons
import { Save } from '@material-ui/icons';

// Image Uploader
import { DropzoneArea } from 'material-ui-dropzone';

// Component Style
import useStyles from './EditClientLayout_Styles';

const EditClientLayout = () => {
    const classes = useStyles();
    const {ClientID} = useParams();
    const {ShowClientState: {ShowClient: {Data}}, ShowClientDispatch} = useContext(DashboardStore);
    const {EditClient, setEditClient, FormEvent, FormValid, FormSubmit, Loading, Error} = EditClientFunction();

    window.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            document.getElementById("Submit").click();
        }
    });

    useEffect(() => {
        ShowClientAction(ClientID)(ShowClientDispatch);
    }, [ClientID, ShowClientDispatch]);
    
    useEffect(() => {
        setEditClient({
            'name': Data.name,
            'description': Data.description
        });
    }, [Data.description, Data.name, setEditClient]);

    const deleteFromState = (DelValue) => {
        const { [DelValue]: value, ...reset } = EditClient;
        setEditClient(reset);
    }

    return (
        <React.Fragment>
            <Box className={classes.header}>
                <Typography className={classes.title} variant="h5" gutterBottom>
                    Edit Client
                    <Box component={'span'}>Edit Client in CME Certificate System.</Box>
                </Typography>
                <Button startIcon={<Save />} onClick={FormSubmit} checked={false} disabled={FormValid || Loading} variant="contained" disableElevation color="primary">
                    {(Loading)? <CircularProgress /> : <React.Fragment>Save</React.Fragment>}
                </Button>
            </Box>
            <Paper elevation={3} className={classes.root}>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3} className={classes.formContent}>
                        <Grid className={classes.dropzone} item lg={3} md={5} xs={12}>
                            {!EditClient.logo &&
                                <img className={classes.defImage} src={`${Data.logo}`} alt=""/>
                            }
                            <DropzoneArea showAlerts={['error']} maxFileSize={5000000} inputProps={{name: "logo"}} filesLimit={1} acceptedFiles={['image/*']} onDelete={() => deleteFromState('logo')} onChange={(files) => files[0] && setEditClient({...EditClient, logo: files[0]})} dropzoneText={"Upload Client Logo"} />
                            {(Error && Error.errors) &&
                                <Typography className={classes.imageError} variant="caption" display="block" color={"error"} gutterBottom>
                                    {Error.errors.logo}
                                </Typography>
                            }
                        </Grid>
                        <Grid item lg={5} md={7} xs={12}>
                            <TextField fullWidth value={EditClient.name || ''} className={classes.input} onChange={FormEvent} type="text" name="name" label="Client Name" variant="outlined" helperText={(Error && Error.errors) && Error.errors.name} />
                            <TextField fullWidth value={EditClient.description || ''} rows={8} className={classes.input} onChange={FormEvent} type="text" name="description" label="Description" variant="outlined" multiline helperText={(Error && Error.errors) && Error.errors.description} />
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </React.Fragment>
    );
};

export default EditClientLayout;
// Actions Types
import {SKIP_SURVEY_LOADING, SKIP_SURVEY_SUCCESS, SKIP_SURVEY_ERRORS} from "../../Types/Views/SkipSurveyType/SkipSurveyType";

const SkipSurveyReducers = (state, {payload, type}) => {
    switch (type) {
        case SKIP_SURVEY_LOADING :
            return {
                ...state,
                SkipSurvey: {
                    ...state.SkipSurvey,
                    Loading: true,
                    Error: false
                }
            }
        case SKIP_SURVEY_SUCCESS :
            return {
                ...state,
                SkipSurvey: {
                    ...state.SkipSurvey,
                    Loading: false,
                    Data: payload,
                }
            }
        case SKIP_SURVEY_ERRORS :
            return {
                ...state,
                SkipSurvey: {
                    ...state.SkipSurvey,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default SkipSurveyReducers;
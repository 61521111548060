// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

const EventsDataAction = (EventID) => (setEventsData) => {
    AxiosInstance()
        .get(`/dashboard/events/${EventID}/stats`)
        .then(Response => (
            setEventsData(Response.data.data)
        ))
        .catch(Error);
}

export default EventsDataAction;
import React, {useContext} from 'react';

// React Router
import {useParams} from 'react-router-dom';

// PDF Generate
import JSPDF from 'jspdf';

// Store Provider
import { ViewsStore } from "../../../Sources/Stores/Views/ViewsStore/ViewsStore";

// Actions
import CertificateAction from "../../../Sources/Actions/Views/CertificateAction/CertificateAction";

// Material-UI Components
import {Box, Button, CircularProgress, Grid, Typography} from "@material-ui/core";

// Component Style
import useStyles from "./CertificateLayout_Styles";
import {CERTIFICATE_LOADING} from "../../../Sources/Types/Views/CertificateType/CertificateType";

const CertificateLayout = ({style, profile, Loading, Certificate}) => {
    const classes = useStyles();
    const { EventID } = useParams();
    const {CertificateState: {Certificate: {Loading: LoadingPDF}}, CertificateDispatch} = useContext(ViewsStore);

    // Download Certificate
    const DownloadCertificate = () => {
        CertificateDispatch({
            type: CERTIFICATE_LOADING,
        })

        const ImagesHandling = (url, callback) => {
            const ImageGenerate = new XMLHttpRequest();
            ImageGenerate.onload = function() {
                const reader = new FileReader();
                reader.onloadend = function() {
                    callback(reader.result.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""));
                }
                reader.readAsDataURL(ImageGenerate.response);
            };
            ImageGenerate.open('GET', url);
            ImageGenerate.responseType = 'blob';
            ImageGenerate.send();
        }

        ImagesHandling(Certificate, function(myBase64) {
            const PDF = new JSPDF('landscape', 'px', 'a4');
            const Width = PDF.internal.pageSize.getWidth();
            const Height = PDF.internal.pageSize.getHeight();

            PDF.addImage(myBase64, 0, 0, Width, Height);
            PDF.setFontSize(24);
            PDF.text(`${profile.first_name + ' ' + profile.last_name}`, Width/2,105, {align: "center"});
            PDF.save("Certificate.pdf");

            const CertificatePDF = {};

            CertificateAction(EventID)(CertificatePDF)(CertificateDispatch)({id: 0});
        });
    }

    // Send Certificate
    const SendCertificate = () => {
        CertificateDispatch({
            type: CERTIFICATE_LOADING,
        })

        const ImagesHandling = (url, callback) => {
            const ImageGenerate = new XMLHttpRequest();
            ImageGenerate.onload = function() {
                const reader = new FileReader();
                reader.onloadend = function() {
                    callback(reader.result.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""));
                }
                reader.readAsDataURL(ImageGenerate.response);
            };
            ImageGenerate.open('GET', url);
            ImageGenerate.responseType = 'blob';
            ImageGenerate.send();
        }

        ImagesHandling(Certificate, function(myBase64) {
            const PDF = new JSPDF('landscape', 'px', 'a4');
            const Width = PDF.internal.pageSize.getWidth();
            const Height = PDF.internal.pageSize.getHeight();

            PDF.addImage(myBase64, 0, 0, Width, Height);
            PDF.setFontSize(24);
            PDF.text(`${profile.first_name + ' ' + profile.last_name}`, Width/2,105, {align: "center"});

            const PDFOutput = PDF.output('blob');
            const CertificatePDF = new FormData();
            CertificatePDF.append('file', PDFOutput);

            CertificateAction(EventID)(CertificatePDF)(CertificateDispatch)({id: 1});
        });
    }

    return (
        <React.Fragment>
            <Grid container spacing={3} className={classes.root}>
                <Box className={classes.title}>
                    <Typography variant="h3" gutterBottom style={{color: `${style.title_color}`}}>
                        Thank You
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Thank you for completing the survey.
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        You can now download your certificate.
                    </Typography>
                </Box>
                {(LoadingPDF) && <CircularProgress />}
                <Grid>
                    <Button onClick={DownloadCertificate} disabled={LoadingPDF} variant="outlined" disableElevation className={classes.outlined}>
                         Download Certificate
                    </Button>
                    <Button onClick={SendCertificate} disabled={LoadingPDF} variant="contained" disableElevation className={classes.submit} style={{backgroundColor: `${style.main_color}`}}>
                        Send To Email
                    </Button>
                </Grid>
                <Grid container spacing={3} className={classes.preview}>
                    <Box className={classes.certificate} style={{backgroundImage: `url(${Certificate})`}}>
                        <Typography className={classes.userName} variant="subtitle1" gutterBottom>
                            {profile.first_name + ' ' + profile.last_name}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );

};

export default CertificateLayout;
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        order:'1',
        width: '80%',
        display:'flex',
        padding: '15px',
        margin: '0 auto',
        maxWidth: '1600px',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection:'column',
        justifyContent:'flex-start',
        minHeight: 'calc(100vh - 110px)',
        backgroundColor: theme.palette.primary.contrastText,
        [theme.breakpoints.down('sm')]: {
            order:'2',
            width:'95%',
            padding: '25px',
        },
    },
    title: {
        padding: theme.spacing(2),
        '& h3': {
            margin: '0',
            fontSize: '40px',
            letterSpacing: '11px',
            textTransform:'uppercase',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(4),
            },
        },
    },
    survey: {
        width: '100%',
        padding: '0 25px',
    },
    submit: {
        fontWeight: '400',
        padding: '8px 65px',
        width: 'max-content',
        borderRadius: '50px',
        margin: '16px auto 0',
        fontSize: theme.spacing(2),
        color: theme.palette.primary.contrastText,
        '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
        },
    },
}));

export default useStyles;
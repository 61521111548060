import React from 'react';

// Material-UI Components
import {Button, Grid, TextField, Box, IconButton} from '@material-ui/core';

// Material-UI Icons
import {AddCircleOutline, Delete} from "@material-ui/icons";

// Component Style
import useStyles from './MultipleChoices_Styles';

const MultipleChoices = (props) => {
    const classes = useStyles();
    const Survey = props.Values.survey_questions;

    const QuestionChange = (Event, Index) => {
        const { name, value } = Event.target;
        const Questions = [...props.Values.survey_questions];
        Questions[Index][name] = value;
        props.SetValues({...props.Values, survey_questions: Questions});
    };

    const AnswerChange = (Event, Index) => {
        const { value } = Event.target;
        const Questions = [...props.Values.survey_questions];
        Questions[props.Index].answers[Index] = value;
        props.SetValues({...props.Values, survey_questions: Questions});
    };

    const AddAnswer = () => {
        const Questions = [...props.Values.survey_questions];
        Questions[props.Index].answers = [...props.Values.survey_questions[props.Index].answers, ''];
        props.SetValues({...props.Values, survey_questions: Questions});
    };

    const RemoveAnswer = (Index) => {
        const Questions = [...props.Values.survey_questions];
        Questions[props.Index].answers.splice(Index, 1);
        props.SetValues({...props.Values, survey_questions: Questions});
    };

    return (
        <React.Fragment>
            <TextField multiline rows={6} fullWidth value={props.Question.question} className={classes.input} style={{marginBottom: '15px'}} type="text" name="question" label="Question" variant="outlined" error={Survey[props.Index].question.length < 3} onChange={(Event) => QuestionChange(Event, props.Index)} />
            <Grid container direction="row" justify="flex-start" alignItems="center">
                {(props.Values?.survey_questions[props.Index]?.answers).map((Answer, Index) => {
                    return (
                        <Box key={Index} className={classes.answer}>
                            <TextField className={classes.input} value={Answer} type="text" name="answers" label={`Choice ${Index + 1}`} variant="outlined" error={!Survey[props.Index].answers[Index].length} onChange={(Event) => AnswerChange(Event, Index)} />
                            {(props.Values.survey_questions[props.Index].answers.length > 2) &&
                                <IconButton className={classes.answerButton} aria-label="delete" onClick={() => RemoveAnswer(Index)}>
                                    <Delete />
                                </IconButton>
                            }
                        </Box>
                    )
                })}
                <Button startIcon={<AddCircleOutline />} variant="contained" color="primary" onClick={AddAnswer}>
                    Add Choice
                </Button>
            </Grid>
        </React.Fragment>
    );
};

export default MultipleChoices;
import React from 'react';

// Styling Components
import {Box, Grid} from '@material-ui/core';

// Custom Styling
import useStyles from './HeaderComponent_Styles';

const HeaderComponent = (props) => {
    const Event = props.event;
    const classes = useStyles();

    return (
        <React.Fragment>
            <Box className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={6} className={classes.brandLogo}>
                        <img src={`${Event.client.logo}`} alt="" />
                    </Grid>
                    <Grid item xs={6} className={classes.eventLogo}>
                        <img src={`${Event.logo}`} alt="" />
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
};

export default HeaderComponent;
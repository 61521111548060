import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        border: '1px solid #c4c4c4',
        backgroundColor: theme.palette.primary.contrastText
    },
    header: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '35px 15px 55px'
    },
    title: {
        margin: 0,
        display: 'grid',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        '& span': {
            fontSize: '14px',
            fontWeight: '400',
            textTransform: 'capitalize',
        }
    },
    input: {
        marginBottom: theme.spacing(1),
        '& label': {
            transform: 'translate(30px, 17px) scale(1)',
            '&.MuiInputLabel-shrink': {
                transform: 'translate(30px, -6px) scale(0.75)',
            },
        },
        '& input': {
            padding: '15px 30px',
        },
        '& textarea': {
            padding: '15px 30px',
        },
        '& fieldset': {
            padding: '0 23px',
        },
        '& .MuiOutlinedInput-multiline': {
            padding: 0,
        },
        '& .MuiFormHelperText-contained': {
            color: theme.palette.error.main
        },
    },
    dropzone: {
        '& .MuiGrid-spacing-xs-8': {
            margin: '0',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            '& > .MuiGrid-item': {
                padding: '15px',
            }
        },
        '& .MuiGrid-grid-xs-4': {
            width: '100%',
            maxWidth: '100%',
        },
        '& .MuiDropzonePreviewList-image': {
            maxWidth: 'initial',
            height: '70px',
        },
    },
}));

export default useStyles;
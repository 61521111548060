// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

// Actions
import EventsAction from "../../../../Sources/Actions/Dashboard/EventsAction/EventsAction";
import ClientInfoAction from "../ClientInfoAction/ClientInfoAction";

// Notifications
import {toast} from 'react-toastify';

// Actions Types
import {DELETE_EVENT_ERRORS, DELETE_EVENT_LOADING, DELETE_EVENT_SUCCESS} from "../../../Types/Dashboard/DeleteEventType/DeleteEventType";

const DeleteEventAction = (EventID) => (ClientID) => (ClientInfoDispatch) => (EventsDispatch) => (DeleteEventDispatch) => {

    DeleteEventDispatch({
        type: DELETE_EVENT_LOADING,
    });

    AxiosInstance()
        .delete(`/dashboard/events/${EventID}`)
        .then(Response => {
            DeleteEventDispatch({
                type: DELETE_EVENT_SUCCESS,
                payload: Response.data,
            });
            EventsAction(EventsDispatch);
            toast('Event has been deleted!', {
                autoClose: 3000,
                draggable: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                position: "top-right",
                hideProgressBar: false,
            });
            (ClientID) && ClientInfoAction(ClientID)(ClientInfoDispatch);
        })
        .catch((Error) => {
            DeleteEventDispatch({
                type: DELETE_EVENT_ERRORS,
                payload: Error.response ? Error.response.data : 'Could Not Connect With API',
            })
            if (Error.response.status === 403) {
                toast("You can't deleted this event because there survey or certificate is taken!", {
                    autoClose: 3000,
                    draggable: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    position: "top-right",
                    hideProgressBar: false,
                });
            }
        });

}

export default DeleteEventAction;
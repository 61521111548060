// Actions Types
import {CERTIFICATE_VIEWS_LOADING, CERTIFICATE_VIEWS_SUCCESS, CERTIFICATE_VIEWS_ERRORS} from "../../Types/Views/CertificateViewsType/CertificateViewsType";

const CertificateViewsReducers = (state, {payload, type}) => {
    switch (type) {
        case CERTIFICATE_VIEWS_LOADING :
            return {
                ...state,
                CertificateViews: {
                    ...state.CertificateViews,
                    Loading: true,
                    Error: false
                }
            }
        case CERTIFICATE_VIEWS_SUCCESS :
            return {
                ...state,
                CertificateViews: {
                    ...state.CertificateViews,
                    Loading: false,
                    Data: payload,
                }
            }
        case CERTIFICATE_VIEWS_ERRORS :
            return {
                ...state,
                CertificateViews: {
                    ...state.CertificateViews,
                    Loading: false,
                    Error: payload,
                }
            }
        default :
            return state;
    }
}

export default CertificateViewsReducers;
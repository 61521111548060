// API Instance
import AxiosInstance from "../../../../Database/Dashboard/AxiosInstance";

const AllEventsDataAction = (setAllEventsData) => {
    AxiosInstance()
        .get(`/dashboard/events/stats`)
        .then(Response => (
            setAllEventsData(Response.data.data)
        ))
        .catch(Error);
}

export default AllEventsDataAction;